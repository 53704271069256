export const parkingProductsTypes = {
  GET_PARKING_PRODUCTS: "GET_PARKING_PRODUCTS",
  GET_LOCATED_PARKING_PRODUCTS: "GET_LOCATED_PARKING_PRODUCTS",
  GET_DELEGATED_PARKING_RIGHTS: "GET_DELEGATED_PARKING_RIGHTS",
  GET_ASSIGNED_VEHICLES: "GET_ASSIGNED_VEHICLES",
  ASSIGN_VEHICLE: 'ASSIGN_VEHICLE',
  DELEGATE_PARKING_RIGHTS: "DELEGATE_PARKING_RIGHTS",
  UPDATE_DELEGATION: "UPDATE_DELEGATION",
  DELETE_DELEGEE: "DELETE_DELEGEE",
  GET_DELEGEE_DETAILS: "GET_DELEGEE_DETAILS",
  CLEAR_DELEGEE_DETAILS: "CLEAR_DELEGEE_DETAILS",
  DISPOSE_ERROR: "DISPOSE_ERROR",
  RESEND_DELEGEE_INVITE: "RESEND_DELEGEE_INVITE",
  REVOKE_PARKINGRIGHTS: "REVOKE_PARKINGRIGHTS",
  GET_DELEGEES: "GET_DELEGEES",
  DELETE_DELEGEES: "DELETE_DELEGEES",
  IMPORT_DELEGEES: "IMPORT_DELEGEES",
  VALIDATE_DELEGEES: "VALIDATE_DELEGEES",
  GET_BULK_IMPORT_PROGRESS: "GET_BULK_IMPORT_PROGRESS",
  GET_BULK_IMPORT_STATUS: "GET_BULK_IMPORT_STATUS",
  RESET_BULK_UPLOAD_PROGRESS: "RESET_BULK_UPLOAD_PROGRESS",
  UPDATE_VEHICLE_ASSIGNMENT: "UPDATE_VEHICLE_ASSIGNMENT",
  GET_ASSIGNED_VEHICLE: "GET_ASSIGNED_VEHICLE",
  REVOKE_VEHICLE_ASSIGNMENTS: "REVOKE_VEHICLE_ASSIGNMENTS",
  GET_VEHICLE_ASSIGNMENTS_MY_FLEET: "GET_VEHICLE_ASSIGNMENTS_MY_FLEET",
  DELETE_VEHICLE_ASSIGNMENTS: "DELETE_VEHICLE_ASSIGNMENTS",
  CALCULATE_WHITELIST_DELTA: "CALCULATE_WHITELIST_DELTA",
};
