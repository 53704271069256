import "../../../scss/_uploadView.scss";
import React from "react";
import { Container, Button, Stepper, Step, StepLabel } from "@material-ui/core";
import Heading from "../../../ui/Heading/Heading";
import { variables } from "../../../theme/variables";
import { CloseOutlined } from "@material-ui/icons";
import CustomButton from "../../../ui/CustomButton/CustomButton";
import useFleetManagerContext from "../../../context/hooks/useFleetManagerContext";
import { useTranslation } from "react-i18next";
import ActionDrawer from "../../../ui/ActionDrawer/ActionDrawer";
import CloseImportWarning from "../../Warnings/CloseImportWarning";
import { connect } from "react-redux";
import VehiclesImportDragAndDrop from "../VehiclesImportDragAndDrop/VehiclesImportDragAndDrop";
import useSmartState from "../../../utils/hooks/useSmartState";
import useImportVehiclesContext from "../../../context/hooks/useImportVehiclesContext";
import { isEmpty } from "underscore";

const { typography } = variables;

const WhitelistVehiclesUploadView = () => {
  const { t } = useTranslation(["whitelistVehicleForm"]);
  const {
    fleetManagerState,
    setVehicleDragAndDropView,
    toggleCloseImportWarning: toggleCloseImportWarning,
  } = useFleetManagerContext();
  
  const closeUploadView = () => {
    setVehicleDragAndDropView(false);
    setVehicleData([]);
  }

  const initialState: VehicleDndState = {
    step: DndStep.FileUpload,
    afterWarningConfirmationAction: closeUploadView,
    showOnlyErrors: true,
    showConfirmationScreen: false,
  };
  
  const { state, updateState } = useSmartState<VehicleDndState>(initialState);
  const {
    importVehiclesState,
    setVehicleData,
    setWhitelistDeltaChanges,
    setProcessing,  
  } = useImportVehiclesContext();

  
  const onClose = () => {
    if (shouldShowDataRemovalWarning()) {
      setAfterWarningConfirmationAction(closeUploadView);
      toggleCloseImportWarning();
      return;
    }

    closeUploadView();
  };

  const shouldShowDataRemovalWarning = () => {
    return (
      importVehiclesState.processing ||
      (importVehiclesState.data &&
        importVehiclesState.data.filter(
          (d) => d.country.value !== "" || d.numberPlate.value !== "" || d.description.value !== ""
        ).length > 0)
    );
  };

  const setAfterWarningConfirmationAction = (
    afterWarningConfirmationAction: () => void
  ) => updateState({ afterWarningConfirmationAction });

  return (
      <div className="upload-view-dnd-container">
        <div className="upload-view-dnd-header">
          <Container className="upload-view-dnd-header-content">
            <Heading
              width="auto"
              justifyContent="center"
              fontSize={typography.fontSizeLarge}
            >
              {t("whitelistVehicles")}
            </Heading>
            <Button
              onClick={onClose}
              classes={{ root: "close-icon-btn" }}
              disableRipple
            >
              <CloseOutlined classes={{ root: "close-icon" }} />
            </Button>
          </Container>
          <Container className="upload-view-dnd-header-stepper">
            <Stepper className="steps" color="#8bb611">
              <Step
                active={state.step === DndStep.FileUpload}
                completed={state.step! > DndStep.FileUpload}
              >
                <StepLabel />
              </Step>
              {(
                <Step
                active={state.step === DndStep.BulkInProgress}
                completed={state.step === DndStep.BulkInProgress && false}
                >
                  <StepLabel />
                </Step>
              )
              }
            </Stepper>
          </Container>
        </div>
        <div className="upload-view-dnd-main">
          <div className="h-full">
            <VehiclesImportDragAndDrop
              show={state.step === DndStep.FileUpload}
              onFileProcessed={(data) => setVehicleData(data)}
              onWhitelistDelta={(onChanges) => setWhitelistDeltaChanges(onChanges)}
              onProcessing={(processing) => setProcessing(processing)}
            />
          </div>
        </div>
        <div className="upload-view-dnd-footer">
          <Container className="upload-view-dnd-footer-content">
          <CustomButton.Light onClick={onClose} text={t("backToSelection")} id="close-invite" />
            {(
              <CustomButton.Primary
                onClick={() => console.log('Upload File')}  
                text={t("whitelistVehicles")}
                disabled={ importVehiclesState.data.every((d) => isEmpty(d.country.value) && isEmpty(d.numberPlate.value) && isEmpty(d.description.value)) ||
                  state.step === DndStep.BulkInProgress || !importVehiclesState.hasWhitelistChanges}
              />
            )}
          </Container>
        </div>
        <ActionDrawer
          open={fleetManagerState.showCloseImportWarning}
          onClose={toggleCloseImportWarning}
          hideBackdrop={true}
        >
          <CloseImportWarning
            onConfirmation={state.afterWarningConfirmationAction ?? onClose}
          />
        </ActionDrawer>
      </div>
  );
};

enum DndStep {
  FileUpload = 0,
  BulkInProgress = 1,
}

interface VehicleDndState {
  step?: DndStep;
  afterWarningConfirmationAction?: () => void;
  showOnlyErrors?: boolean;
  showConfirmationScreen?: boolean;
}

export default connect(null,)(WhitelistVehiclesUploadView);
