import React, { Dispatch, useState } from "react";
import { Container } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { NoPaddingBottomGrid } from "../../DataTable/DataTableStyledComponents";
import Heading from "../../../ui/Heading/Heading";
import { variables } from "../../../theme/variables";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import { isEmpty } from "underscore";
import { connect } from "react-redux";
import useAppContext from "../../../context/hooks/useAppContext";
import { VehicleData } from "../../../context/store/reducers/importVehicles.reducer";
import useImportVehiclesContext from "../../../context/hooks/useImportVehiclesContext";
import WhitelistDragAndDrop, { DndError, DndState, whitelistDelta } from "../WhitelistDragAndDrop/WhitelistDragAndDrop";
import VehicleBulkFileValidator from "./ValidateVehicleBulkFile";
import { CalculatedWhitelistDeltaResponse, CalculateWhitelistDeltaRequest, VehicleInfo } from "../../../models/vehicle-assignments/CalculateWhitelistDelta";
import parkingProductsActions from "../../../store/actions/parkingProducts.actions";

const { typography } = variables;

const dprSheetName = "Import Vehicles";
const countryName = "Country";
const numberPlateName = "Number Plate";
const descriptionName = "Description";
const rowNumber = "__rowNum__";

const VehiclesImportDragAndDrop: React.FC<VehiclesImportDragAndDropProps> = (
  props
) => {
  const [state, setState] = useState<DndState>("default");
  const [error, setError] = useState<DndError | undefined>(undefined);
  const [invalidVehicleCount, setInvalidVehicleCount] = useState<number>();
  const [fileReference, setFileReference] = useState<string>();
  const [whitelistDelta, setWhitelistDelta] = useState<whitelistDelta | undefined>(undefined);
  const { appState } = useAppContext();

  const { importVehiclesState } = useImportVehiclesContext();

  const { t } = useTranslation(["whitelistVehicleForm"]);
  const supportedFileTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];

  const handleFileUpload = (file: File) => {
    setState("processing");
    props.onProcessing(true);
    readVehiclesFromXls(file);
  };

  const readVehiclesFromXls = (file: File) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = handleUploadedData;
  };

  const handleUploadedData = (e: ProgressEvent<FileReader>) => {
    const vehiclesToImport: VehicleData[] = [];

    const data = e.target?.result;
    const wb = XLSX.read(data, { type: "binary" });

    if (wb.SheetNames && wb.SheetNames.includes(dprSheetName)) {
      readDataFromExcel(wb, vehiclesToImport);

      const fileIsEmpty = isEmpty(vehiclesToImport);
      if (fileIsEmpty) {
        setState("error");
        setError("empty");
        props.onProcessing(false);
        return;
      }

      const products = [...importVehiclesState.products];

      const countValidationErrors = VehicleBulkFileValidator.ValidateFile(vehiclesToImport)
      const req: CalculateWhitelistDeltaRequest = {
        seasonTicketOwnerCrmId: appState.user.seasonTicketOwnerCrmId as string,
        pmcIds: products,
        vehicles: vehiclesToImport.map(v => ({value: v.numberPlate.value, countryCode: v.country.value, description: v.description.value} as VehicleInfo) ) 
      };

      if (countValidationErrors === 0) {
        props.calculateWhitelistDelta(
          req,
          (data) => {

            props.onProcessing(false);
            props.onFileProcessed(vehiclesToImport);

            const delta: whitelistDelta = {
              amountOfVehiclesToCreate: data.amountOfVehiclesToCreate,
              amountOfVehiclesToDelete: data.amountOfVehiclesToDelete,
              amountOfVehiclesToUpdate: data.amountOfVehiclesToUpdate,
              amountOfVehiclesWithNoChanges: data.amountOfVehiclesWithNoChanges,
              hasChanges: data.hasChanges
            };

            props.onWhitelistDelta(delta.hasChanges);
            setWhitelistDelta(delta);
            setFileReference(data.fileReference);
            setWhitelistDelta(delta);
            setState("success");
          },
          (errorCode) => {
            setState("error");
            setError("unexpected");
          });
      }
      else {
        setState("error");
        setError("invalid");
        setInvalidVehicleCount(countValidationErrors);
      } 
    } else {
      setState("error");
      setError("invalid");
    }
  };

  const readDataFromExcel = (wb: XLSX.WorkBook, vehiclesToImport: VehicleData[]) => {
    const rows: any[] = XLSX.utils.sheet_to_json(wb.Sheets[dprSheetName]);
    rows.forEach((row) => {
      if (row[countryName] || row[numberPlateName] || row[descriptionName]) {
        let vehicle: VehicleData;
        vehicle = {
          country: { value: row[countryName], isValid: true },
          numberPlate: { value: row[numberPlateName], isValid: true },
          description: { value: row[descriptionName], isValid: true },
          lineNumber: row[rowNumber] + 1
        };
        vehiclesToImport.push(vehicle);
      }
    });
  }


  if (!props.show) {
    return null;
  }

  return (
    <React.Fragment>
      {error === undefined && (<Container>
        <NoPaddingBottomGrid container item direction="row" alignItems="center">
          <NoPaddingBottomGrid container item direction="column" xs={6}>
            <Heading fontSize={typography.fontSizeBase}>
              <strong>{t("uploadFile")}</strong>
            </Heading>
          </NoPaddingBottomGrid>
          <NoPaddingBottomGrid container item direction="column" xs={6}>
            <div className="download-template">
              <div>
                <a
                  href="/files/VehicleImportTemplate.xlsx"
                  target="_blank"
                  download
                >
                  {t("downloadTemplate")}
                </a>
                <KeyboardArrowDown />
              </div>
            </div>
          </NoPaddingBottomGrid>
        </NoPaddingBottomGrid>
      </Container>)}
      <Container className="h-full">
        <WhitelistDragAndDrop
          onFileUploaded={handleFileUpload}
          supportedFileTypes={supportedFileTypes}
          state={state}
          error={error}
          whitelistDelta={whitelistDelta}
          seasonTicketOwnerCrmId={appState.user.seasonTicketOwnerCrmId as string}
          invalidVehicleCount={invalidVehicleCount}
        />
      </Container>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  calculateWhitelistDelta: (
    req: CalculateWhitelistDeltaRequest,
    onSuccess: (response: CalculatedWhitelistDeltaResponse) => void,
    onError: (errorCode: string) => void) =>
    dispatch(parkingProductsActions.calculateWhitelistDelta(
      req,
      onSuccess,
      onError))
});

interface VehiclesImportDragAndDropProps extends DispatchProps {
  show: boolean;
  onFileProcessed: (data: VehicleData[]) => void;
  onWhitelistDelta: (hasChanges: boolean) => void;
  onProcessing: (processing: boolean) => void;
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(null, mapDispatchToProps)(VehiclesImportDragAndDrop);
