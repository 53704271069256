import { importVehiclesTypes } from "../actions/importVehicles.types";
export interface EnsureValidValue<T> {
  value: T;
  isValid: boolean;
  errorMessageKey?: string
}

export interface VehicleData {
  country: EnsureValidValue<string>;
  numberPlate: EnsureValidValue<string>;
  description: EnsureValidValue<string>;
  lineNumber?: number;
}

export interface ImportVehiclesState {
  data: VehicleData[];
  products: number[];
  processing: boolean;
  hasWhitelistChanges: boolean;
}

export const initialState: ImportVehiclesState = {
  data: [],
  products: [],
  hasWhitelistChanges: false,
  processing: false,
};

export type ImportVehiclesAction = {
  type: string;
  payload?: VehicleData | VehicleData[] | number[] | boolean;
};

export default (
  state: ImportVehiclesState = initialState,
  action: ImportVehiclesAction
): ImportVehiclesState => {
  switch (action.type) {
    case importVehiclesTypes.SET_VEHICLES_DATA:
      return {
        ...state,
        data: action.payload as VehicleData[],
      };
    case importVehiclesTypes.SET_VEHICLES_PROCESSING:
      return {
        ...state,
        processing: action.payload as boolean,
      };
    case importVehiclesTypes.SET_SELECTED_PRODUCTS:
      return {
        ...state,
        products: action.payload as number[],
      };
    case importVehiclesTypes.SET_WHITELIST_CHANGES:
      return {
        ...state,
        hasWhitelistChanges: action.payload as boolean,
      };
    case importVehiclesTypes.RESET_VEHICLES:
      return initialState;
    default:
      return state;
  }
};
