import { findWhere, isUndefined } from "underscore";
import { CountryNumberPlate } from "./CountryNumberPlate";
import { languages } from "../translations";

export class CountriesNumberPlates {
  private numberPlates: CountryNumberPlate[];

  constructor() {
    this.numberPlates = this.getNumberPlates();
  }

  private getNumberPlates = (): CountryNumberPlate[] => [
    this.mapNumberPlate("Austria", "A", "AT", "+44"),
    this.mapNumberPlate("Albania", "AL", "AL", "+335"),
    this.mapNumberPlate("Angola", "ANG", "AO", "+244"),
    this.mapNumberPlate("Australia", "AUS", "AU", "+43"),
    this.mapNumberPlate("Azerbaijan", "AZ", "AZ", "+994"),
    this.mapNumberPlate("Belgium", "B", "BE", "+32"),
    this.mapNumberPlate("Bulgaria", "BG", "BG", "+359"),
    this.mapNumberPlate("Bahrain", "BH", "BH", "+973"),
    this.mapNumberPlate("Bosnia and Herzegovina", "BIH", "BA", "+387"),
    this.mapNumberPlate("Brazil", "BR", "BR", "+55"),
    this.mapNumberPlate("Belarus", "BY", "BY", "+375"),
    this.mapNumberPlate("Canada", "CAN", "CA", "+1"),
    this.mapNumberPlate("Switzerland", "CH", "CH", "+41"),
    this.mapNumberPlate("China", "CHN", "CN", "+86"),
    this.mapNumberPlate("Colombia", "CO", "CO", "+57"),
    this.mapNumberPlate("Czech Republic", "CZ", "CZ", "+420"),
    this.mapNumberPlate("Germany", "D", "DE", "+49"),
    this.mapNumberPlate("Denmark", "DK", "DK", "+45"),
    this.mapNumberPlate("Algeria", "DZ", "DZ", "+213"),
    this.mapNumberPlate("Spain", "E", "ES", "+34"),
    this.mapNumberPlate("Kenia", "EAK", "KE", "+254"),
    this.mapNumberPlate("Ecuador", "EC", "EC", "+593"),
    this.mapNumberPlate("Egypt", "EG", "EG", "+20"),
    this.mapNumberPlate("Estonia", "EST", "EE", "+372"),
    this.mapNumberPlate("France", "F", "FR", "+33"),
    this.mapNumberPlate("Finland", "FIN", "FI", "+358"),
    this.mapNumberPlate("Liechtenstein", "FL", "LI", "+423"),
    this.mapNumberPlate("Guatemala", "G", "GT", "+502"),
    this.mapNumberPlate("Great Britain", "GB", "GB", "+44"),
    this.mapNumberPlate("Georgia", "GE", "GE", "+995"),
    this.mapNumberPlate("Greece", "GR", "GR", "+30"),
    this.mapNumberPlate("Hungary", "H", "HU", "+36"),
    this.mapNumberPlate("Hong Kong", "HK", "HK", "+852"),
    this.mapNumberPlate("Croatia", "HR", "HR", "+385"),
    this.mapNumberPlate("Italy", "I", "IT", "+39"),
    this.mapNumberPlate("Israel", "IL", "IL", "+972"),
    this.mapNumberPlate("India", "IND", "IN", "+91"),
    this.mapNumberPlate("Ireland", "IRL", "IE", "+353"),
    this.mapNumberPlate("Iceland", "ISL", "IS", "+354"),
    this.mapNumberPlate("Saudi Arabia", "KSA", "SA", "+966"),
    this.mapNumberPlate("Kuwait", "KWT", "KW", "+965"),
    this.mapNumberPlate("Kazakhstan", "KZ", "KZ", "+7"),
    this.mapNumberPlate("Luxemburg", "L", "LU", "+352"),
    this.mapNumberPlate("Lebanon", "LIB", "LB", "+961"),
    this.mapNumberPlate("Lithuania", "LT", "LT", "+370"),
    this.mapNumberPlate("Latvia", "LV", "LV", "+371"),
    this.mapNumberPlate("Macao", "MAC", "MO", "+853"),
    this.mapNumberPlate("Morocco", "MAR", "MA", "+212"),
    this.mapNumberPlate("Monaco", "MC", "MC", "+377"),
    this.mapNumberPlate("Mexico", "MEX", "MX", "+52"),
    this.mapNumberPlate("Macedonia", "MK", "MK", "+389"),
    this.mapNumberPlate("Montenegro", "MNE", "ME", "+382"),
    this.mapNumberPlate("Mauritius", "MS", "MU", "+230"),
    this.mapNumberPlate("Malaysia", "MY", "MY", "+60"),
    this.mapNumberPlate("Norway", "N", "NO", "+47"),
    this.mapNumberPlate("Netherlands", "NL", "NL", "+31"),
    this.mapNumberPlate("New Zealand", "NZL", "NZ", "+64"),
    this.mapNumberPlate("Oman", "OM", "OM", "+968"),
    this.mapNumberPlate("Portugal", "P", "PT", "+351"),
    this.mapNumberPlate("Peru", "PE", "PE", "+51"),
    this.mapNumberPlate("Poland", "PL", "PL", "+48"),
    this.mapNumberPlate("Qatar", "QA", "QA", "+974"),
    this.mapNumberPlate("Argentina", "RA", "AR", "+54"),
    this.mapNumberPlate("Chile", "RCH", "CL", "+56"),
    this.mapNumberPlate("Romania", "RO", "RO", "+40"),
    this.mapNumberPlate("Uruguay", "ROU", "UY", "+598"),
    this.mapNumberPlate("Russia", "RUS", "RU", "+7"),
    this.mapNumberPlate("Sweden", "S", "SE", "+46"),
    this.mapNumberPlate("Slovakia", "SK", "SK", "+421"),
    this.mapNumberPlate("Slovenia", "SLO", "SI", "+386"),
    this.mapNumberPlate("Turkey", "TR", "TR", "+90"),
    this.mapNumberPlate("Ukraine", "UA", "UA", "+380"),
    this.mapNumberPlate("United Arab Emirates", "UAE", "AE", "+971"),
    this.mapNumberPlate("United States of America", "USA", "US", "+1"),
    this.mapNumberPlate("Serbia", "YU", "RS", "+381"),
    this.mapNumberPlate("Venezuela", "YV", "VU", "+58"),
    this.mapNumberPlate("South Africa", "ZA", "ZA", "+27"),
  ];

  private getNumberPlatesDVA = (): CountryNumberPlate[] => [
    this.mapNumberPlate("Belgium", "B", "BE", "+32"),
    this.mapNumberPlate("Germany", "D", "DE", "+49"),
    this.mapNumberPlate("Denmark", "DK", "DK", "+45"),
    this.mapNumberPlate("France", "F", "FR", "+33"),
    this.mapNumberPlate("Great Britain", "GB", "GB", "+44"),
    this.mapNumberPlate("Ireland", "IRL", "IE", "+353"),
    this.mapNumberPlate("Netherlands", "NL", "NL", "+31"),
  ];

  private mapNumberPlate = (
    name: string,
    numberPlateCode: string,
    countryCode: string,
    phoneNumberPrefix: string
  ): CountryNumberPlate => ({
    name,
    countryCode,
    numberPlateCode,
    phoneNumberPrefix,
  });

  public getCountriesNumberPlates = () => this.numberPlates;
  public getTopCountriesNumberPlatesDVA = () => this.getNumberPlatesDVA();

  public findNumberPlatePrefix = (countryCode?: string): string => {
    if (isUndefined(countryCode) || countryCode === "") {
      return "";
    }

    const found = findWhere(this.numberPlates, { countryCode : countryCode.toUpperCase() });
    if (isUndefined(found)) {
      return "";
    }

    return found.numberPlateCode;
  };

  public isValidNumberPlateCode = (countryCode?: string): boolean => {
    if (isUndefined(countryCode) || countryCode === "") {
      return false;
    }

    return this.numberPlates.some(plate => plate.numberPlateCode === countryCode.toUpperCase());
  };

  public isValidCountryCode = (countryCode?: string): boolean => {
    if (isUndefined(countryCode) || countryCode === "") {
      return false;
    }

    return this.numberPlates.some(plate => plate.countryCode === countryCode.toUpperCase());
  };
}

const numberPlatePrefixToIsoCountryCode = Object.fromEntries(
  new CountriesNumberPlates()
    .getCountriesNumberPlates()
    .map((x) => [x.numberPlateCode, x.countryCode])
);

const isoCountryCodeToNumberPlatePrefix = Object.fromEntries(
  new CountriesNumberPlates()
    .getCountriesNumberPlates()
    .map((x) => [x.countryCode, x.numberPlateCode])
);


export function convertNumberPlatePrefixToIsoCountryCode(
  numberPlateCode: string
): string {
  const isoCountryCode = numberPlatePrefixToIsoCountryCode[numberPlateCode];
  if (!isoCountryCode) {
    throw new Error(`Can't find country code that corresponds to ${numberPlateCode}`);
  }
  return isoCountryCode;
}

export function convertIsoCountryCodeToNumberPlatePrefix(
  isoCode: string
): string {
  const numberPlatePrefix = isoCountryCodeToNumberPlatePrefix[isoCode.toUpperCase()];
  if (!numberPlatePrefix) {
    throw new Error(`Can't find country code that corresponds to ${isoCode}`);
  }
  return numberPlatePrefix;
}

export function moveCountryToTop(countryCode: string, countryNumberplates: CountryNumberPlate[]): CountryNumberPlate[]{
    const countryNumberPlate = findWhere(countryNumberplates, { countryCode : countryCode.toUpperCase() });

    if(!countryNumberPlate){
      return countryNumberplates;
    }

    const index = countryNumberplates.indexOf(countryNumberPlate,0);

    if (index > -1) {
      countryNumberplates.splice(index, 1);
    }

    countryNumberplates.unshift(countryNumberPlate)

    return countryNumberplates;
}

export function getCountryCodeByLanguage(language: string): string {
  switch(language) { 
    case languages.en: { 
      return "NL";
    } 
    case languages.nl: { 
      return "NL";
    } 
    case languages.de: { 
      return "DE";
     } 
     case languages.fr: { 
      return "FR";
    }
    default: { 
      return "NL";
    } 
 } 
}
