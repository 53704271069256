import { ParkingProductModel } from "../models/ParkingProductModel";
import axios from "../axios";
import parkingProductsApi, { GetVehicleAssignmentsQuery } from "../api/parkingProducts.api";
import { AxiosResponse } from "axios";
import { ParkingRightsRequestModel } from "../models/ParkingRightsRequestModel";
import {
  AssignedParkingRightsWithPagination,
  DelegatedParkingRightsWithPagination,
  DelegeesWithPagination,
  DelegeeWithProductsModel,
  FlexBudgetUnitType,
  VehicleAssignmentModel,
  VehicleAssignmentsMyFleetWithPagination,
} from "../models/delegations/DelegatedParkingProductsModel";
import { LocatedParkingProduct } from "../models/LocatedParkingProduct";
import { RevokeParkingRightsModel } from "../models/RevokeParkingRightsModel";
import { DeleteDelegationsRequestModel } from "../models/delegations/DeleteDelegationsRequestModel";
import { WithFiltersAndPaging } from "../models/filters/WithFiltersAndPaging";
import { ForFleetManager } from "../models/ForFleetManager";
import queryParam from "../utils/queryParam.utils";
import { DataTableContextFilters, DateFilter } from "../components/DataTable/DataTableContext/DataTableContextProvider";
import { ValidateDelegeesResponseModel } from "../models/bulkImport/ValidateDelegeesResponseModel";
import { ValidateDelegeesRequestModel } from "../models/bulkImport/ValidateDelegeesRequestModel";
import { StartBulkInvitationsRequestModel } from "../models/bulkImport/StartBulkInvitationsRequestModel";
import { GetBulkImportProgressResponseModel } from "../models/bulkImport/GetBulkImportProgressResponseModel";
import { GetBulkImportStatusResponseModel } from "../models/bulkImport/GetBulkImportStatusResponseModel";
import { AssignVehicleRequestModel } from "../models/vehicle-assignments/AssignVehicleRequestModel";
import { UpdateVehicleRequestModel } from "../models/vehicle-assignments/UpdateVehicleRequestModel";
import { AssignVehicleResponseModel } from "../models/vehicle-assignments/AssignVehicleResponseModel";
import { FmpFilters } from "../constants/filtering.constants";
import { isNil } from "lodash";
import { RevokeVehicleAssignmentsModel } from "../models/vehicle-assignments/RevokeVehicleAssignmentsModel";
import { DeleteVehicleAssignmentsModel } from "../models/vehicle-assignments/DeleteVehicleAssignmentsModel";
import moment from "moment";
import { UpdateVehicleResponseModel } from "../models/vehicle-assignments/UpdateVehicleResponseModel";
import { CalculatedWhitelistDeltaResponse, CalculateWhitelistDeltaRequest } from "../models/vehicle-assignments/CalculateWhitelistDelta";

type ParkingProductsService = {
  getParkingProducts: (
    seasonTicketOwnerCrmId: string,
    location: string
  ) => Promise<AxiosResponse<ParkingProductModel[]>>;
  delegateParkingRights: (
    model: ParkingRightsRequestModel
  ) => Promise<AxiosResponse>;
  updateDelegation: (
    model: ParkingRightsRequestModel
  ) => Promise<AxiosResponse>;
  getDelegatedParkingProducts: (
    delegatedParkingRightsRequest: WithFiltersAndPaging<ForFleetManager>
  ) => Promise<AxiosResponse<DelegatedParkingRightsWithPagination>>;
  getVehicles: (query: GetVehicleAssignmentsQuery) => Promise<AxiosResponse<AssignedParkingRightsWithPagination>>;
  getLocatedParkingProducts: (
    seasonTicketOwnerCrmId: string,
    language: string
  ) => Promise<AxiosResponse<LocatedParkingProduct[]>>;
  getDelegeeDetails: (
    registrationId: string,
    language: string
  ) => Promise<AxiosResponse<DelegeeWithProductsModel>>;
  deleteDelegee: (registrationId: string) => Promise<AxiosResponse>;
  resendDelegeeInvite: (
    registrationId: string,
    seasonTicketOwnerCrmId: string
  ) => Promise<AxiosResponse>;
  getDelegees: (
    request: WithFiltersAndPaging<ForFleetManager>
  ) => Promise<AxiosResponse<DelegeesWithPagination>>;
  revokeParkingRights: (
    model: RevokeParkingRightsModel
  ) => Promise<AxiosResponse>;
  deleteDelegations: (
    request: DeleteDelegationsRequestModel
  ) => Promise<AxiosResponse>;
  validateDelegees: (
    seasonTicketOwnerCrmId: string,
    req: ValidateDelegeesRequestModel
    ) => Promise<AxiosResponse<ValidateDelegeesResponseModel>>;
  getDelegeesValidationResult: (
    seasonTicketOwnerCrmId: string,
    fileReference: string,
    language: string
    ) => Promise<AxiosResponse<Blob>>;
  getDelegeesInvitationResult: (
    seasonTicketOwnerCrmId: string,
    fileReference: string,
    language: string
    ) => Promise<AxiosResponse<Blob>>;
  startBulkInvitations: (
    seasonTicketOwnerCrmId: string,
    fileReference: string,
    request: StartBulkInvitationsRequestModel,
  ) => Promise<AxiosResponse>
  getBulkImportProgress: (
    seasonTicketOwnerCrmId: string,
    fileReference: string
    ) => Promise<AxiosResponse<GetBulkImportProgressResponseModel>>;
  getBulkImportStatus: (
    seasonTicketOwnerCrmId: string
  ) => Promise<AxiosResponse<GetBulkImportStatusResponseModel>>;
  assignVehicle: (seasonTicketOwnerCrmId: string, req: AssignVehicleRequestModel) => Promise<AxiosResponse<AssignVehicleResponseModel>>;
  updateVehicleAssignment: (seasonTicketOwnerCrmId: string, aggregateId: string, req: UpdateVehicleRequestModel) => Promise<AxiosResponse<UpdateVehicleResponseModel>>;
  getVehicleAssignment: (aggregateId: string) => Promise<AxiosResponse<VehicleAssignmentModel>>;
  revokeVehicleAssignments: (model: RevokeVehicleAssignmentsModel) => Promise<AxiosResponse>;
  deleteVehicleAssignments: (model: DeleteVehicleAssignmentsModel) => Promise<AxiosResponse>;
  getVehicleAssignmentsMyFleet: (query: GetVehicleAssignmentsQuery) 
    => Promise<AxiosResponse<VehicleAssignmentsMyFleetWithPagination>>;
  calculateWhitelistDelta: (
      req: CalculateWhitelistDeltaRequest
      ) => Promise<AxiosResponse<CalculatedWhitelistDeltaResponse>>;
}

const getParkingProducts = (
  seasonTicketOwnerCrmId: string,
  location: string
): Promise<AxiosResponse<ParkingProductModel[]>> => {
  return axios.get(
    parkingProductsApi.getParkingProductsUrl(seasonTicketOwnerCrmId, location)
  );
};

const getLocatedParkingProducts = (
  seasonTicketOwnerCrmId: string,
  language: string
) => {
  return axios.get<LocatedParkingProduct[]>(
    parkingProductsApi.getLocatedParkingProductsUrl(
      seasonTicketOwnerCrmId,
      language
    )
  );
};

const delegateParkingRights = (
  model: ParkingRightsRequestModel
): Promise<AxiosResponse> => {
  return axios.post(parkingProductsApi.getDelegateParkingRightsUrl(), model);
};

const updateDelegation = (
  model: ParkingRightsRequestModel
): Promise<AxiosResponse> => {
  return axios.put(parkingProductsApi.getUpdateDelegationUrl(), model);
};

const getDelegatedParkingProducts = (
  delegatedParkingRightsRequest: WithFiltersAndPaging<ForFleetManager>
): Promise<AxiosResponse<DelegatedParkingRightsWithPagination>> => {
  const params = queryParam.buildQueryParam(
    delegatedParkingRightsRequest.filters as DataTableContextFilters[],
    delegatedParkingRightsRequest.pagination
  );

  return axios.get(
    parkingProductsApi.getDelegatedParkingRightsUrl(
      delegatedParkingRightsRequest.entity.seasonTicketOwnerCrmId,
      delegatedParkingRightsRequest.entity.language
    ),
    { params }
  );
};

function convertDateFilter(start: Date, end?: Date | null): { start: string; end: string } {
  const convertedStart = moment(start).startOf("day");
  if (isNil(end)) {
    end = start;
  }
  const convertedEnd = moment(end).startOf("day").add(1, "day").subtract(1, "second");

  return {
    start: convertedStart.toISOString(true),
    end: convertedEnd.toISOString(true),
  };
}

export function convertFiltersToGetVehicleAssignmentsQuery(
  r: WithFiltersAndPaging<ForFleetManager>
): GetVehicleAssignmentsQuery {
  const { filters } = r;
  const assignedDateFilter = filters?.find((x) => x.key === FmpFilters.assignedDate)?.value as
    | DateFilter
    | undefined;
  const startedDateFilter = filters?.find((x) => x.key === FmpFilters.startedDate)?.value as
    | DateFilter
    | undefined;

  const convertedAssginedDateFilter =
    isNil(assignedDateFilter) || isNil(assignedDateFilter.startDate)
      ? undefined
      : convertDateFilter(assignedDateFilter.startDate, assignedDateFilter.endDate);
  const convertedStartedDateFilter =
    isNil(startedDateFilter) || isNil(startedDateFilter.startDate)
      ? undefined
      : convertDateFilter(startedDateFilter.startDate, startedDateFilter.endDate);

  return {
    seasonTicketOwnerCrmId: r.entity.seasonTicketOwnerCrmId,
    placeId: Number(filters?.find((x) => x.key === FmpFilters.placeId)?.value),
    language: r.entity.language,
    page: r.pagination.pageNumber,
    pageSize: r.pagination.pageSize,
    assignedDateStart: convertedAssginedDateFilter?.start,
    assignedDateEnd: convertedAssginedDateFilter?.end,
    ascending: r.filters?.find((x) => x.key === FmpFilters.orderDesc)?.value === "false",
    numberPlateValue: filters?.find((x) => x.key === FmpFilters.numberPlate)?.value as any,
    pmcIds: filters?.find((x) => x.key === FmpFilters.products)?.value as any,
    sortBy: filters?.find((x) => x.key == FmpFilters.sortBy)?.value as any,
    description: filters?.find((x) => x.key === FmpFilters.description)?.value as any,
    StartedDateStart: convertedStartedDateFilter?.start,
    StartedDateEnd: convertedStartedDateFilter?.end
  };
}

async function getVehicles(
  query: GetVehicleAssignmentsQuery
): Promise<AxiosResponse<AssignedParkingRightsWithPagination>> {
  const url = parkingProductsApi.getVehicleAssignmentsUrl(query);

  type ApiDto = {
    items: Array<{
      vehicleAssignmentId: string;
      parkingRightId: string;
      pmcId: number;
      pmcName: string;
      locationName: string;
      description?: string;
      numberPlate: {
        countryCode: string;
        value: string;
      };
      assignedAt: string;
      startedAt: string;
      flexBudgetRemainingTime: number;
      flexBudgetUnitType: FlexBudgetUnitType;
    }>;
    filteredCount: number;
  };

  const map = (dto: ApiDto): AssignedParkingRightsWithPagination => {
    const pages = Math.ceil(dto.filteredCount / query.pageSize);

    return {
      assignedParkingRights: dto.items.map((x) => ({
        vehicleAssignmentId: x.vehicleAssignmentId,
        parkingRight: {
          id: x.parkingRightId,
          pmc: x.pmcId,
          location: x.locationName,
          name: x.pmcName,
        },
        assigned: new Date(x.assignedAt),
        startedAt: x.startedAt ? new Date(x.startedAt) : null,
        vehicle: {
          countryCode: x.numberPlate.countryCode,
          identifier: x.numberPlate.value,
          description: x.description,
        },
        flexBudgetRemainingTime: x.flexBudgetRemainingTime,
        flexBudgetUnitType: x.flexBudgetUnitType
      })),
      pages,
      totalRecords: dto.filteredCount,
    };
  };

  const response = await axios.get<ApiDto>(url);

  return {
    ...response,
    data: response.data && map(response.data),
  };
}

const getDelegeeDetails = (
  registrationId: string,
  language: string
): Promise<AxiosResponse<DelegeeWithProductsModel>> => {
  return axios.get(
    parkingProductsApi.getDelegeeDetailsUrl(registrationId, language)
  );
};

const deleteDelegee = (registrationId: string): Promise<AxiosResponse> => {
  return axios.delete(parkingProductsApi.getDeleteDelegeeUrl(registrationId));
};

const resendDelegeeInvite = (
  registrationId: string,
  seasonTicketOwnerCrmId: string
): Promise<AxiosResponse> => {
  return axios.post(
    parkingProductsApi.getResendDelegeeInviteUrl(
      registrationId,
      seasonTicketOwnerCrmId
    )
  );
};

const getDelegees = (
  request: WithFiltersAndPaging<ForFleetManager>
): Promise<AxiosResponse<DelegeesWithPagination>> => {
  const params = queryParam.buildQueryParam(
    request.filters as DataTableContextFilters[],
    request.pagination
  );

  return axios.get(
    parkingProductsApi.getDelegeesUrl(
      request.entity.seasonTicketOwnerCrmId,
      request.entity.language
    ),
    { params }
  );
};

const validateDelegees = (
  seasonTicketOwnerCrmId: string,
  req: ValidateDelegeesRequestModel
): Promise<AxiosResponse<ValidateDelegeesResponseModel>> => {
  return axios.post(
    parkingProductsApi.getValidateDelegeesUrl(seasonTicketOwnerCrmId),
    req
  );
};

const getDelegeesValidationResult = (
    seasonTicketOwnerCrmId: string,
    fileReference: string,
    language: string
): Promise<AxiosResponse<Blob>> => {
    return axios.get(
        parkingProductsApi.getDelegeesValidationResultUrl(seasonTicketOwnerCrmId, fileReference,language),
        {
            responseType: "blob",
        }
    );
};

const getDelegeesInvitationResult = (
  seasonTicketOwnerCrmId: string,
  fileReference: string,
  language: string
): Promise<AxiosResponse<Blob>> => {
  return axios.get(
      parkingProductsApi.getDelegeesInvitationResultUrl(seasonTicketOwnerCrmId, fileReference, language),
      {
          responseType: "blob",
      }
  );
};

const startBulkInvitations  = (
  seasonTicketOwnerCrmId: string,
  fileReference: string,
  req: StartBulkInvitationsRequestModel): Promise<AxiosResponse<ValidateDelegeesResponseModel>> => {
    return axios.post(parkingProductsApi.getStartBulkInvitationsUrl(seasonTicketOwnerCrmId, fileReference), req);
  }

const getBulkImportProgress = (
    seasonTicketOwnerCrmId: string,
    fileReference: string
): Promise<AxiosResponse<GetBulkImportProgressResponseModel>> => {
    return axios.get(parkingProductsApi.getBulkImportProgressUrl(seasonTicketOwnerCrmId, fileReference));
};

const getBulkImportStatus = (
  seasonTicketOwnerCrmId: string,
): Promise<AxiosResponse<GetBulkImportStatusResponseModel>> => {
  return axios.get(parkingProductsApi.getBulkImportStatusUrl(seasonTicketOwnerCrmId));
}

const assignVehicle = (
  seasonTicketOwnerCrmId: string,
  req: AssignVehicleRequestModel
): Promise<AxiosResponse<AssignVehicleResponseModel>> => {
  return axios.post(parkingProductsApi.getAssignVehicleUrl(seasonTicketOwnerCrmId), req);
}

const updateVehicleAssignment = (
  seasonTicketOwnerCrmId: string,
  aggregateId: string,
  req: UpdateVehicleRequestModel
): Promise<AxiosResponse<UpdateVehicleResponseModel>> => {
  return axios.post(parkingProductsApi.getUpdateVehicleAssignmentUrl(seasonTicketOwnerCrmId, aggregateId), req);
}

const getVehicleAssignment = (
  aggregateId: string
): Promise<AxiosResponse<VehicleAssignmentModel>> => {
  return axios.get(parkingProductsApi.getVehicleAssignmentUrl(aggregateId));
}

async function getVehicleAssignmentsMyFleet(query: GetVehicleAssignmentsQuery): Promise<AxiosResponse<VehicleAssignmentsMyFleetWithPagination>> {
  const url = parkingProductsApi.getVehicleAssignmentsMyFleetUrl(query);

  type vehicleAssignmentProductDto = {
    parkingRightId: string;
    pmcId: number;
    pmcName: string;
    locationName: string;
  }
  type vehicleAssignmentsDto = {
    items: Array<{
      vehicleAssignmentId: string;
      description?: string;
      numberPlate: {
        countryCode: string;
        value: string;
      };
      assignedAt: string;
      products: vehicleAssignmentProductDto[]
    }>;
    filteredCount: number;
  };

  const map = (dto: vehicleAssignmentsDto): VehicleAssignmentsMyFleetWithPagination => {
    const pages = Math.ceil(dto.filteredCount / query.pageSize);

    return {
      vehicleAssignments: dto.items.map((x) => ({
        vehicleAssignmentId: x.vehicleAssignmentId,        
        assignedAt: new Date(x.assignedAt),
        vehicle: {
          countryCode: x.numberPlate.countryCode,
          identifier: x.numberPlate.value,
          description: x.description,
        },
        products: x.products.map(p => { return { parkingRightId: p.parkingRightId, pmcId: p.pmcId, pmcName: p.pmcName, locationName: p.locationName }})
      })),
      pages,
      totalRecords: dto.filteredCount,
    };
  };

  const response = await axios.get<vehicleAssignmentsDto>(url);

  return {
    ...response,
    data: response.data && map(response.data),
  };
}

const revokeParkingRights = (
  model: RevokeParkingRightsModel
): Promise<AxiosResponse> => {
  return axios.post(parkingProductsApi.getRevokeParkingRightsUrl(), model);
};

const deleteDelegations = (request: DeleteDelegationsRequestModel) => {
  return axios.post(parkingProductsApi.getDeleteDelegeesUrl(), request);
};

const revokeVehicleAssignments = (
  model: RevokeVehicleAssignmentsModel
): Promise<AxiosResponse> => {
  return axios.post(parkingProductsApi.getRevokeVehicleAssignmentsUrl(), model);
};

const deleteVehicleAssignments = (
    model: DeleteVehicleAssignmentsModel
): Promise<AxiosResponse> => {
    return axios.post(parkingProductsApi.getDeleteVehicleAssignmentsUrl(), model);
};

const calculateWhitelistDelta = (
  req: CalculateWhitelistDeltaRequest
): Promise<AxiosResponse<CalculatedWhitelistDeltaResponse>> => {
  return axios.post(
    parkingProductsApi.getCalculateWhitelistDeltaUrl(),
    req
  );
};

const service: ParkingProductsService = {
  getParkingProducts,
  getLocatedParkingProducts,
  delegateParkingRights,
  updateDelegation,
  getDelegatedParkingProducts,
  getDelegeeDetails,
  getVehicles,
  deleteDelegee,
  resendDelegeeInvite,
  getDelegees,
  revokeParkingRights,
  deleteDelegations,
  validateDelegees,
  getDelegeesValidationResult,
  getDelegeesInvitationResult,
  startBulkInvitations,
  getBulkImportProgress,
  getBulkImportStatus,
  assignVehicle,
  updateVehicleAssignment,
  getVehicleAssignment,
  getVehicleAssignmentsMyFleet,
  revokeVehicleAssignments,
  deleteVehicleAssignments,
  calculateWhitelistDelta
};

export default service;