import React, { Dispatch, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Section from "../../../ui/Section/Section";
import { variables } from "../../../theme/variables";
import DelegeeForm from "../../../components/DelegeeForm/DelegeeForm";
import { useTranslation } from "react-i18next";
import ActionDrawer from "../../../ui/ActionDrawer/ActionDrawer";
import { Helmet } from "react-helmet";
import Spacer from "../../../ui/Spacer/Spacer";
import useMyProductsContext from "../../../context/hooks/useMyProductsContext";
import LocationCards from "./LocationCards/LocationCards";
import DeleteDelegeeWarning from "../../../components/Warnings/DeleteDelegeeWarning";
import BatchRevokeParkingRightsWarning from "../../../components/Warnings/BatchRevokeParkingRightsWarning";
import BatchRevokeVehicleAssignmentWarning from "../../../components/Warnings/BatchRevokeVehicleAssignmentWarning";
import DeleteVehiclesAssignmentWarning from "../../../components/Warnings/DeleteVehiclesAssignmentWarning";
import { Position } from "../../../components/LocationCard/locationCard.business";
import useFleetManagerContext from "../../../context/hooks/useFleetManagerContext";
import parkingProductsActions from "../../../store/actions/parkingProducts.actions";
import useAppContext from "../../../context/hooks/useAppContext";
import { connect } from "react-redux";
import { WithFiltersAndPaging } from "../../../models/filters/WithFiltersAndPaging";
import { ForFleetManager } from "../../../models/ForFleetManager";
import { DataTableContextFilters, useDataTableContext } from "../../../components/DataTable/DataTableContext/DataTableContextProvider";
import { FmpFilters } from "../../../constants/filtering.constants";
import { FilterType } from "../../../components/DataTable/DataTableFilters/DataTableFilters";
import MultipleDelegeesForm from "../../../components/MultipleDelegeesForm/MultipleDelegeesForm";
import "./_myProducts.scss";
import ImportDelegationsContextProvider from "../../../context/ImportDelegationsContext";
import ImportVehiclesContext from "../../../context/ImportVehiclesContext";
import { useOnBulkImportFinishedCallback } from "../../../hooks/useOnBulkImportFinishedCallback";
import TableSwitcher, { TabOption } from "./ParkingRightsDataTable/TableSwitcher";
import FleetMembersTable from "./ParkingRightsDataTable/FleetMembersTable";
import VehiclesTable from "./ParkingRightsDataTable/VehiclesTable";
import { useAppSelector } from "../../../store";
import { selectUseBulkVehicleAssignments, selectUseSingleDirectVehicleAssignment, useCurrentlySelectedParkingProduct } from "./ParkingRightsDataTable/common";
import VehicleForm from "../../../components/VehicleForm/VehicleForm";
import { VehicleAssignmentProduct } from "../../../models/vehicle-assignments/VehicleAssignmentProduct";
import RevokeVehicleAssignmentWarning from "../../../components/Warnings/RevokeVehicleAssignmentWarning";
import RevokeParkingRightsWarning from "../../../components/Warnings/RevokeParkingRightsWarning";
import WhitelistVehiclesForm from "../../../components/WhitelistVehiclesForm/WhitelistVehiclesForm";
import WhitelistVehiclesUploadView from "../../../components/WhitelistVehiclesForm/WhitelistVehiclesUploadView/WhitelistVehiclesUploadView";

const { colors } = variables;

interface IProps extends DispatchProps { }
const MyProducts = ({ getDelegatedParkingRights, getLocatedParkingProducts, getVehicles }: IProps) => {
  const { appState } = useAppContext();
  const {
    myProductsState,
    resetContext: resetMyProductsContext,
    setSorting
  } = useMyProductsContext();
  const { state: dataTable, resetContext, resetFilters, resetPageNumber, resetPageSize } = useDataTableContext();

  const {
    fleetManagerState,
    toggleAddDelegee,
    toggleAssignVehicle,
    toggleEditVehicleAssignment,
    toggleEditDelegee,
    toggleWhitelistVehicles,
    toggleDeleteDelegeeWarning,
    toggleBatchRevokeParkingRightsWarning,
    toggleBatchRevokeVehicleAssignmentsWarning,
    toggleRevokeParkingRightsWarning,
    toggleRevokeVehicleAssignmentsWarning,
    toggleDeleteVehicleAssignmentsWarning,
    setSelectedParkingRightIds,
    resetContext: resetFleetManagerContext,
  } = useFleetManagerContext();

  const [tab, setTab] = useState(TabOption.FleetMembers);

  const useSingleDirectVehicleAssignment = useAppSelector(selectUseSingleDirectVehicleAssignment);
  const useBulkVehicleAssignments = useAppSelector(selectUseBulkVehicleAssignments);

  const selectedLocatedProduct = useCurrentlySelectedParkingProduct();
  const switcher = !useSingleDirectVehicleAssignment || useBulkVehicleAssignments ? (
    <></>
  ) : (
    <TableSwitcher
      tab={tab}
      onChange={(newTab) => {
        if (tab !== newTab) {
          resetPageNumber();
          resetPageSize();
          setSelectedParkingRightIds([]);

          let sorting = "";
          switch (newTab) {
            case TabOption.FleetMembers:
              sorting = "invitationDate";
              break;
            case TabOption.Vehicles:
              sorting = "assignedDate";
              break;
          }

          resetContext();
          setSorting(sorting, true);
        }
        setTab(newTab);
      }}
      getCount={(option) => {
        switch (option) {
          case TabOption.FleetMembers:
            return selectedLocatedProduct?.totalDelegatedParkingRights ?? 0;
          case TabOption.Vehicles:
            return selectedLocatedProduct?.totalAssignedParkingRights ?? 0
        }
      }}
    />
  );
  const { t } = useTranslation(["myProducts", "globals", "dataTable"]);
  const getMetaTitle = () =>
    fleetManagerState.showDelegeeDetails ? t("addDelegee") : t("myProducts");

  useEffect(() => {
    if (useBulkVehicleAssignments) {
      setTab(TabOption.Vehicles);
    }
  }, [useBulkVehicleAssignments]);

  useEffect(() => {
    return () => {
      resetMyProductsContext();
      resetFleetManagerContext();
      resetContext();
    };
    // eslint-disable-next-line
  }, []);

  const onDelegeeFormClose = () => {
    if (fleetManagerState.isEditDelegeeMode) {
      toggleEditDelegee("");
    } else {
      toggleAddDelegee();
    }
  };

  const onVehicleFormClose = () => {
    if (fleetManagerState.isEditVehicleAssignmentMode) {
      toggleEditVehicleAssignment();
    } else {
      toggleAssignVehicle();
    }
  };

  const onWhitelistVehiclesClose = () => {
    toggleWhitelistVehicles();
  };

  const [position, setPosition] = useState<Position>({
    left: 0,
    right: 0,
    width: 0,
  });

  const onLocationCardSelect = (pos?: Position) => {
    resetFilters();
    setSelectedParkingRightIds([]);

    setPosition({
      ...position,
      left: pos?.left as number,
      right: pos?.right as number,
      width: pos?.width as number,
    });
  };

  const onRefresh = (vehiclesToExclude?: VehicleAssignmentProduct[], excludeByVehicleAssignmentId?: boolean) => {
    getLocatedParkingProducts(
      appState.user.seasonTicketOwnerCrmId as string,
      appState.selectedLanguage
    );

    const delegatedParkingRightsRequest: WithFiltersAndPaging<ForFleetManager> = {
      entity: {
        seasonTicketOwnerCrmId: appState.user.seasonTicketOwnerCrmId as string,
        language: appState.selectedLanguage,
      },
      pagination: {
        pageSize: dataTable.pageSize!,
        pageNumber: dataTable.pageNumber!,
      },
      filters: [
        {
          key: FmpFilters.placeId,
          type: FilterType.QueryParam,
          value: myProductsState.selectedLocation.toString(),
        },
        ...(dataTable.filters as DataTableContextFilters[]),
      ],
    };

    if (tab === TabOption.FleetMembers) {
      getDelegatedParkingRights(delegatedParkingRightsRequest);
    } else {
      getVehicles(delegatedParkingRightsRequest, vehiclesToExclude, excludeByVehicleAssignmentId);
    }
  };

  useOnBulkImportFinishedCallback(onRefresh);

  const getDeleteDelegeeWarningText = (parkingRightsCount: number): string =>
    t("deleteDelegeeWarning.warning", { count: parkingRightsCount });

  const getDeleteDelegeeWarningDetails = (parkingRightsCount: number): string =>
    t("deleteDelegeeWarning.details", { count: parkingRightsCount });

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Q-Park | ${getMetaTitle()}`}</title>
      </Helmet>
      <ImportDelegationsContextProvider>
        <React.Fragment>
          <ActionDrawer
            open={fleetManagerState.showDelegeeDetails && !fleetManagerState.dragAndDropView}
            onClose={onDelegeeFormClose}
            className="delegee-form-drawer"
          >
            <DelegeeForm
              onClose={onDelegeeFormClose}
              refreshHandler={onRefresh}
              registrationId={fleetManagerState.selectedRegistrationId}
            />
          </ActionDrawer>
          {fleetManagerState.showDelegeeDetails && <MultipleDelegeesForm onClose={onDelegeeFormClose} />}
        </React.Fragment>
      </ImportDelegationsContextProvider>
      <ActionDrawer
        open={fleetManagerState.showDeleteDelegeeWarning}
        onClose={toggleDeleteDelegeeWarning}
      >
        <DeleteDelegeeWarning
          getWarningText={getDeleteDelegeeWarningText}
          getWarningDetails={getDeleteDelegeeWarningDetails}
          onRefresh={onRefresh}
        />
      </ActionDrawer>
      <ActionDrawer
        open={fleetManagerState.showBatchRevokeParkingRightsWarning}
        onClose={toggleBatchRevokeParkingRightsWarning}
      >
        <BatchRevokeParkingRightsWarning
          onRefresh={onRefresh}
          selectedParkingRightIds={fleetManagerState.selectedRowKeys}
        />
      </ActionDrawer>
      <ActionDrawer
        open={fleetManagerState.showBatchRevokeParkingVehicleAssignment}
        onClose={toggleBatchRevokeVehicleAssignmentsWarning}
      >
        <BatchRevokeVehicleAssignmentWarning
          onRefresh={onRefresh}
          selectedVehicleAssignmentKeys={fleetManagerState.selectedRowKeys}
        />
      </ActionDrawer>
      <ActionDrawer
        open={fleetManagerState.showRevokeParkingRightsWarning}
        onClose={toggleRevokeParkingRightsWarning}
      >
        <RevokeParkingRightsWarning
          onRefresh={onRefresh}
          selectedParkingRightIds={[fleetManagerState.selectedDelegeeRowKey]}
        />
      </ActionDrawer>
      <ActionDrawer
        open={fleetManagerState.showRevokeParkingVehicleAssignment}
        onClose={toggleRevokeVehicleAssignmentsWarning}
      >
        <RevokeVehicleAssignmentWarning
          onRefresh={onRefresh}
        />
      </ActionDrawer>
      <ActionDrawer
        open={fleetManagerState.showDeleteVehiclesAssignment}
        onClose={toggleDeleteVehicleAssignmentsWarning}
      >
        <DeleteVehiclesAssignmentWarning
          onRefresh={onRefresh}
          selectedVehicleAssignmentKeys={fleetManagerState.selectedRowKeys}
        />
      </ActionDrawer>
      <ActionDrawer
        open={fleetManagerState.showAssignVehicle}
        onClose={onVehicleFormClose}
      >
        <VehicleForm onClose={onVehicleFormClose} />
      </ActionDrawer>
      <ImportVehiclesContext>
        <ActionDrawer open={fleetManagerState.showWhitelistVehicles && !fleetManagerState.vehicleDragAndDropView} onClose={onWhitelistVehiclesClose}>
          <WhitelistVehiclesForm onClose={onWhitelistVehiclesClose} /> 
        </ActionDrawer>
        <ActionDrawer open={fleetManagerState.showWhitelistVehicles && fleetManagerState.vehicleDragAndDropView}>
          <WhitelistVehiclesUploadView ></WhitelistVehiclesUploadView>
        </ActionDrawer>
      </ImportVehiclesContext>
      <Spacer />
      <LocationCards onCardSelected={onLocationCardSelect} />
      <Spacer size="sm" />
      {myProductsState.selectedLocation !== 0 && (
        <Grid container direction="row" alignItems="center">
          <Section
            backgroundColor={colors.white}
            withArrow
            left={position.left! - position.width! / 2.5}
          >
            {tab === TabOption.FleetMembers &&
              <FleetMembersTable
                selectedParkingProduct={selectedLocatedProduct?.parkingProducts}
                onRevokeClicked={toggleBatchRevokeParkingRightsWarning}
                subHeading={switcher} />
            }
            {tab === TabOption.Vehicles &&
              <VehiclesTable
                selectedParkingProduct={selectedLocatedProduct?.parkingProducts}
                onRevokeClicked={toggleBatchRevokeVehicleAssignmentsWarning}
                onDeleteClicked={toggleDeleteVehicleAssignmentsWarning}
                subHeading={switcher} />
            }
          </Section>
        </Grid>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getLocatedParkingProducts: (seasonTicketOwnerCrmId: string, language: string) => {
    dispatch(parkingProductsActions.getLocatedParkingProducts(seasonTicketOwnerCrmId, language));
  },
  getDelegatedParkingRights: (request: WithFiltersAndPaging<ForFleetManager>) =>
    dispatch(parkingProductsActions.getDelegatedParkingProducts(request)),

  getVehicles: (request: WithFiltersAndPaging<ForFleetManager>, vehiclesToExclude?: VehicleAssignmentProduct[], excludeByVehicleAssignmentId?: boolean) => {
    dispatch(parkingProductsActions.getVehicles(request, vehiclesToExclude, excludeByVehicleAssignmentId));
  }
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(null, mapDispatchToProps)(MyProducts);
