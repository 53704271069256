import React, { memo, useEffect, useRef, useState } from "react";
import { NoPaddingBottomGrid } from "../../DataTable/DataTableStyledComponents";
import CircleImage from "../../../ui/CircleImage/CircleImage";
import XlsFileAdd from "../../../assets/images/xls-file-add.svg";
import XlsFileDropping from "../../../assets/images/xls-file-dropping.svg";
import XlsFileEmpty from "../../../assets/images/xls-file-empty.svg";
import XlsFileChecked from "../../../assets/images/xls-file-checked.svg";
import { variables } from "../../../theme/variables";
import Heading from "../../../ui/Heading/Heading";
import ImgBg from "../../../ui/ImgBg/ImgBg.";
import { contains, first } from "underscore";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../ui/CustomButton/CustomButton";
import "../../../scss/_dragAndDrop.scss";
import useAppContext from "../../../context/hooks/useAppContext";

const { typography } = variables;

const WhitelistDragAndDrop: React.FC<DragAndDropProps> = (props) => {
  const { t } = useTranslation(["whitelistDragAndDrop", "downloadFile"]);
  const [state, setState] = useState<DndState>(props.state);
  const [error, setError] = useState<DndError | undefined>(props.error);
  const [whitelistDelta, setWhitelistDelta] = useState<whitelistDelta | undefined>(props.whitelistDelta);
  const { appState } = useAppContext();

  const fileInput = useRef<HTMLInputElement>(null);
  const maxFileSizeInMBFallBack = 2;
  const bytesPerMB = 1000000;

  useEffect(() => {
    if (props.state !== state) {
      setState(props.state);
    }
    if (props.error !== error) {
      setError(props.error);
    }

    if (props.whitelistDelta !== whitelistDelta) {
      setWhitelistDelta(props.whitelistDelta);
    }

    if (props.state === "default" && fileInput.current) {
      fileInput.current.files = null;
      fileInput.current.value = "";
    }
  }, [props.state, props.error, props.whitelistDelta]);

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleWhitelistFile(e.target.files as FileList);
  };

  const handleWhitelistFile = (files: FileList) => {
    if (state === "processing") return;

    const isValid = isValidDrop(files as FileList);

    if(!isValid){
      return;
    }

    setState("processing");

    const file = first(files) as File;
    props.onFileUploaded(file);

    if (fileInput.current) {
        fileInput.current.value = "";
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (state === "processing") return;

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setState("dragging");
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setState("default");
  };

  const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();

    handleWhitelistFile(e.dataTransfer.files);
  };

  const isValidDrop = (files: FileList): boolean => {
    if (files.length > 1) {
      setState("error");
      return false;
    }

    const file = first(files);
    if (!isSupportedFileType((file as File)?.type)) {
      setState("error");
      return false;
    }

    if(reachedMaxFileSize(file as File)){
      setError("maxFileSize");
      setState("error");
      return false
    }

    return true;
  };

  const reachedMaxFileSize = (file: File) => {
    if (file!.size > (props.maxFileSizeInMB ?? maxFileSizeInMBFallBack) * bytesPerMB) {
      return true;
    }

    return false;
  }

  const isSupportedFileType = (fileType: string): boolean =>
    contains(props.supportedFileTypes, fileType);

  const getImgWrapperSrc = () => {
    switch (state) {
      case "error":
        return XlsFileEmpty;
      case "success":
        return XlsFileChecked;
      default:
        return XlsFileAdd;
    }
  };


  const onDownloadFile = () => {
    // TODO create csv file
  }

  const emptyFileView = () => {
    return (
      <div>
          <Heading width="auto" justifyContent="center" fontSize={typography.fontSizeLarge}>
            {t("emptyFileDetected")}
          </Heading>
          <div><br/>
          <span className="text-dark-gray-2nd">
            {t("uploadEmptyFile")}
          </span><br/>
          <span className="text-dark-gray-2nd">
            {t("uploadEmptyFileContinue")}
          </span><br/>
          <span className="text-dark-gray-2nd">
            {t("uploadEmptyFileDontContinue")}
          </span>
        </div>
      </div>
    );
  }

  const excelFileSizeToLargeView = () => {
    return (
      <div>
          <Heading width="auto" justifyContent="center" fontSize={typography.fontSizeLarge}>
            {t("noVehiclesUploaded")}
          </Heading>
          <div><br/>
          <span className="text-dark-gray-2nd">
            {t("excelFileSizeToLarge")}
          </span>
        </div>
      </div>
    );
  }

  const invalidValidationView = () => {
    return (
      <div>
          <Heading width="auto" justifyContent="center" fontSize={typography.fontSizeLarge}>
            {t("noVehiclesUploaded")}
          </Heading>
          <div><br/>
          <span className="text-dark-gray-2nd">
            {t("validateVehicleDetailsError", { errorsCount: props.invalidVehicleCount, })}
          </span>
          <span className="text-dark-gray-2nd download-file-btn">
            <div><br/>
              <CustomButton.Light
                  text={t("downloadFile:downloadErrorDetails")}
                  onClick={onDownloadFile}
              />
            </div>
          </span>
        </div>
      </div>
    );
  }

  const unexpectedErrorView = () => {
    return (
      <div>
          <Heading width="auto" justifyContent="center" fontSize={typography.fontSizeLarge}>
            {t("noVehiclesUploaded")}
          </Heading>
          <div><br/>
          <span className="text-dark-gray-2nd">
            {t("unexpectedError", { errorsCount: props.invalidVehicleCount, })}
          </span>
        </div>
      </div>
    );
  }

  const getSuccessView = () => {
    if (!props.whitelistDelta?.hasChanges) {
        return successWithoutChangesView();
    }

    return successChangesView();
  }

  const successChangesView = () => {
    return (
      <div>
        <Heading width="auto" justifyContent="center" fontSize={typography.fontSizeLarge}>
        {t("successFile")}
        </Heading>
        <div><br/>
          <span className="text-dark-gray-2nd">
            {t("successFileChangesUnchanged", { noChangesCount: props.whitelistDelta?.amountOfVehiclesWithNoChanges, })}
              <br/>
              {t("successFileChangesAdded", { addedCount: props.whitelistDelta?.amountOfVehiclesToCreate, })}
              <br/>
              {t("successFileChangesUpdated", { updateCount: props.whitelistDelta?.amountOfVehiclesToUpdate, })}
              <br/>
              {t("successFileChangesRemoved", { removeCount: props.whitelistDelta?.amountOfVehiclesToDelete, })}
          </span>
        </div> 
      </div>
    );
  }

  const successWithoutChangesView = () => {
    return (
      <div>
          <Heading width="auto" justifyContent="center" fontSize={typography.fontSizeLarge}>
            {t("noVehiclesUploaded")}
          </Heading>
          <div><br/>
          <span className="text-dark-gray-2nd">
            {t("noFileChangesDetected")}
          </span>
        </div>
      </div>
    );
  }

  const getErrorView = () => {

    if (error === "empty") {
      return emptyFileView();
    }

    if(error === "maxFileSize") {
      return excelFileSizeToLargeView();
    }

    if(error === "invalid") {
      return invalidValidationView();
    }

    if(error === "unexpected") {
      return unexpectedErrorView();
    }

    return unexpectedErrorView();
  }

  return (
    <div className={`drag-and-drop ${state}`}>
      <div
        className={`dnd-box ${state === "dragging" && "on-dragging"} ${
          state === "processing" && "on-processing"
        }`}
      >
        {state !== "success" &&
          props.error !== "invalid" && (
            <label
              className="drop-zone"
              htmlFor={`${state !== "dragging" && "file"}`}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
            />
          )}
        <div className="dnd-box-input">
          <input
            ref={fileInput}
            type="file"
            name="file"
            id="file"
            multiple={false}
            accept={props.supportedFileTypes.join(",")}
            onChange={handleFileSelected}
            disabled={state === "processing"}
          />
          <NoPaddingBottomGrid
            container
            item
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <NoPaddingBottomGrid
              container
              item
              direction="column"
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              <div className="img-wrapper">
                <CircleImage src={getImgWrapperSrc()} />
              </div>
              <div className="dragging-img-wrapper">
                <ImgBg src={XlsFileDropping} />
                <span className="sonar-wave" />
              </div>
            </NoPaddingBottomGrid>
            <NoPaddingBottomGrid
              container
              item
              direction="column"
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              <div className="dnd-file-choose-label">
                <Heading width="auto" justifyContent="center" fontSize={typography.fontSizeLarge}>
                  {t("dragAndDropHere")}
                </Heading>
                <div className="input-label">
                  {
                    <span className="text-dark-gray-2nd">
                      {t("or")}&nbsp;
                      <span className="dnd-link">{t("browseFiles")}</span>
                    </span>
                  }
                </div>
              </div>
              <div className="dnd-error-label">
                { getErrorView() }
              </div>
              <div className="dnd-success-label">
                { getSuccessView() }
              </div>
              <div className="dnd-file-dropping-label">
                <Heading width="auto" justifyContent="center" fontSize={typography.fontSizeLarge}>
                  {t("releaseFile")}
                </Heading>
                <span className="text-dark-gray-2nd">{t("releaseFileSubtitle")}</span>
              </div>
              <div className="dnd-file-processing-label">
                <Heading width="auto" justifyContent="center" fontSize={typography.fontSizeLarge}>
                  {t("processingFile")}
                </Heading>
                <span className="text-dark-gray-2nd">{t("processingFileSubtitle")}</span>
              </div>
            </NoPaddingBottomGrid>
          </NoPaddingBottomGrid>
        </div>
      </div>
        <div className="dnd-accepted-files">
          <NoPaddingBottomGrid container item direction="row">
            <NoPaddingBottomGrid container item direction="column" xs={12}>
               
                <span>{t("acceptedFileTypes")}</span>
              
            </NoPaddingBottomGrid>
            <NoPaddingBottomGrid container item direction="column" xs={12}>
              <span>{t("multipleFilesNotSupported")}</span>
            </NoPaddingBottomGrid>
            <NoPaddingBottomGrid container item direction="column" xs={12}>
              <span>
                {t("maxFileSize", {
                  maxFileSize: props.maxFileSizeInMB ?? maxFileSizeInMBFallBack,
                })}
              </span>
            </NoPaddingBottomGrid>
          </NoPaddingBottomGrid>
        </div>
    </div>
  );
};

export type DndState = "default" | "dragging" | "error" | "processing" | "success";

export type DndError = "empty" | "invalid" | "unexpected" | "maxFileSize";

export type whitelistDelta = {
  amountOfVehiclesToUpdate: number;
  amountOfVehiclesToCreate: number;
  amountOfVehiclesToDelete: number;
  amountOfVehiclesWithNoChanges: number;
  hasChanges: boolean;
}

interface DragAndDropProps {
  onFileUploaded: (file: File) => void;
  state: DndState;
  error?: DndError;
  whitelistDelta?: whitelistDelta;
  supportedFileTypes: string[];
  extraSuccessMsg?: string;
  maxFileSizeInMB?: number;
  seasonTicketOwnerCrmId?: string;
  invalidVehicleCount?: number;
}

export default memo(WhitelistDragAndDrop);
