export type DeRegion = {
    code: string
    name: string
}

function region(code: string, name: string) : DeRegion {
    return { code, name };
}

const deRegions : DeRegion[] = [
    region("0", "Diplomaten"),
    region("A", "Augsburg, Bayern"),
    region("AA", "Ostalbkreis (Aalen), Baden-Württemberg"),
    region("AB", "Aschaffenburg, Bayern"),
    region("ABG", "Altenburger Land, Thüringen"),
    region("ABI", "Anhalt-Bitterfeld, Sachsen-Anhalt"),
    region("AC", "Aachen, Nordrhein-Westfalen"),
    region("AE", "Auerbach, Sachsen"),
    region("AH", "Ahaus, Nordrhein-Westfalen"),
    region("AIB", "Bad Aibling, Bayern"),
    region("AIC", "Aichach-Friedberg, Bayern"),
    region("AK", "Altenkirchen, Rheinland-Pfalz"),
    region("AL", "Altena, Nordrhein-Westfalen"),
    region("ALF", "Alfeld (Leine), Niedersachsen"),
    region("ALS", "Alsfeld, Hessen"),
    region("ALZ", "Alzenau, Bayern"),
    region("AM", "Amberg, Bayern"),
    region("AN", "Ansbach, Bayern"),
    region("ANA", "Annaberg, Sachsen"),
    region("ANG", "Angermünde, Brandenburg"),
    region("ANK", "Anklam, Mecklenburg-Vorpommern"),
    region("AÖ", "Altötting, Bayern"),
    region("AP", "Weimarer Land (Apolda), Thüringen"),
    region("APD", "Apolda, Thüringen"),
    region("AR", "Arnsberg, Nordrhein-Westfalen"),
    region("ARN", "Arnstadt, Thüringen"),
    region("ART", "Artern, Thüringen"),
    region("AS", "Amberg-Sulzbach, Bayern"),
    region("ASD", "Aschendorf-Hümmling, Niedersachsen"),
    region("ASL", "Aschersleben-Staßfurt, Sachsen-Anhalt"),
    region("ASZ", "Aue-Schwarzenberg, Sachsen"),
    region("AT", "Altentreptow, Mecklenburg-Vorpommern"),
    region("AU", "Aue, Sachsen"),
    region("AUR", "Aurich, Niedersachsen"),
    region("AW", "Ahrweiler, Rheinland-Pfalz"),
    region("AZ", "Alzey-Worms, Rheinland-Pfalz"),
    region("AZE", "Anhalt-Zerbst, Sachsen-Anhalt"),
    region("B", "Berlin, Berlin"),
    region("BA", "Bamberg, Bayern"),
    region("BAD", "Baden-Baden, Baden-Württemberg"),
    region("BAR", "Barnim, Brandenburg"),
    region("BB", "Böblingen, Baden-Württemberg"),
    region("BBG", "Bernburg, Sachsen-Anhalt"),
    region("BC", "Biberach, Baden-Württemberg"),
    region("BCH", "Buchen (Odenwald), Baden-Württemberg"),
    region("BD", "Bundestag, -rat, -präsidialamt,-regierung und -verfassungsgericht"),
    region("BE", "Beckum, Nordrhein-Westfalen"),
    region("BED", "Brand-Erbisdorf, Sachsen"),
    region("BEI", "Beilngries, Bayern"),
    region("BEL", "Belzig, Brandenburg"),
    region("BER", "Bernau, Brandenburg"),
    region("BF", "Burgsteinfurt, Nordrhein-Westfalen"),
    region("BGD", "Berchtesgaden, Bayern"),
    region("BGL", "Berchtesgadener Land, Bayern"),
    region("BH", "Bühl, Baden-Württemberg"),
    region("BI", "Bielefeld, Nordrhein-Westfalen"),
    region("BID", "Biedenkopf, Hessen"),
    region("BIN", "Bingen am Rhein, Rheinland-Pfalz"),
    region("BIR", "Birkenfeld, Rheinland-Pfalz"),
    region("BIT", "Bitburg-Prüm (Eifelkreis), Rheinland-Pfalz"),
    region("BIW", "Bischofswerda, Sachsen"),
    region("BK", "Backnang, Baden-Württemberg; Landkreis Börde Sachsen-Anhalt"),
    region("BKS", "Bernkastel-Kues, Rheinland-Pfalz"),
    region("BL", "Zollernalbkreis (Balingen), Baden-Württemberg"),
    region("BLB", "Berleburg, Nordrhein-Westfalen"),
    region("BLK", "Burgenlandkreis, Sachsen-Anhalt"),
    region("BM", "Rhein-Erft-Kreis (Bergheim), Nordrhein-Westfalen"),
    region("BN", "Bonn, Nordrhein-Westfalen"),
    region("BNA", "Borna, Sachsen"),
    region("BO", "Bochum, Nordrhein-Westfalen"),
    region("BÖ", "Bördekreis, Sachsen-Anhalt"),
    region("BOG", "Bogen, Bayern"),
    region("BOH", "Bocholt, Nordrhein-Westfalen"),
    region("BOR", "Borken, Nordrhein-Westfalen"),
    region("BOT", "Bottrop, Nordrhein-Westfalen"),
    region("BP", "Bundespolizei"),
    region("BR", "Bruchsal, Baden-Württemberg"),
    region("BRA", "Wesermarsch (Brake), Niedersachsen"),
    region("BRB", "Brandenburg, Brandenburg"),
    region("BRG", "Burg, Sachsen-Anhalt"),
    region("BRI", "Brilon, Nordrhein-Westfalen"),
    region("BRK", "Bad Brückenau, Bayern"),
    region("BRL", "Blankenburg (Braunlage), Niedersachsen"),
    region("BRV", "Bremervörde, Niedersachsen"),
    region("BS", "Braunschweig, Niedersachsen"),
    region("BSB", "Bersenbrück, Niedersachsen"),
    region("BSK", "Beeskow, Brandenburg"),
    region("BT", "Bayreuth, Bayern"),
    region("BTF", "Bitterfeld, Sachsen-Anhalt"),
    region("BU", "Burgdorf, Niedersachsen"),
    region("BÜD", "Büdingen, Hessen"),
    region("BUL", "Burglengenfeld, Bayern"),
    region("BÜR", "Büren, Nordrhein-Westfalen"),
    region("BÜS", "Büsingen (Konstanz), Baden-Württemberg"),
    region("BÜZ", "Bützow, Mecklenburg-Vorpommern"),
    region("BW", "Wasser- und Schifffahrtsverwaltung des Bundes"),
    region("BZ", "Bautzen, Sachsen"),
    region("BZA", "Bergzabern, Rheinland-Pfalz"),
    region("C", "Chemnitz, Sachsen"),
    region("CA", "Calau, Brandenburg"),
    region("CAS", "Castrop-Rauxel, Nordrhein-Westfalen"),
    region("CB", "Cottbus, Brandenburg"),
    region("CE", "Celle, Niedersachsen"),
    region("CHA", "Cham, Bayern"),
    region("CLP", "Cloppenburg, Niedersachsen"),
    region("CLZ", "Clausthal-Zellerfeld, Niedersachsen"),
    region("CO", "Coburg, Bayern"),
    region("COC", "Cochem-Zell, Rheinland-Pfalz"),
    region("COE", "Coesfeld, Nordrhein-Westfalen"),
    region("CR", "Crailsheim, Baden-Württemberg"),
    region("CUX", "Cuxhaven, Niedersachsen"),
    region("CW", "Calw, Baden-Württemberg"),
    region("D", "Düsseldorf, Nordrhein-Westfalen"),
    region("DA", "Darmstadt, Hessen"),
    region("DAH", "Dachau, Bayern"),
    region("DAN", "Lüchow-Dannenberg, Niedersachsen"),
    region("DAU", "Daun, Rheinland-Pfalz"),
    region("DBR", "Bad Doberan, Mecklenburg-Vorpommern"),
    region("DD", "Dresden, Sachsen"),
    region("DE", "Dessau-Roßlau, Sachsen-Anhalt"),
    region("DEG", "Deggendorf, Bayern"),
    region("DEL", "Delmenhorst, Niedersachsen"),
    region("DGF", "Dingolfing-Landau, Bayern"),
    region("DH", "Diepholz, Niedersachsen"),
    region("DI", "Dieburg, Hessen"),
    region("DIL", "Dillkreis (Dillenburg), Hessen"),
    region("DIN", "Dinslaken, Nordrhein-Westfalen"),
    region("DIZ", "Diez, Rheinland-Pfalz"),
    region("DKB", "Dinkelsbühl, Bayern"),
    region("DL", "Döbeln, Sachsen"),
    region("DLG", "Dillingen a.d. Donau, Bayern"),
    region("DM", "Demmin, Mecklenburg-Vorpommern"),
    region("DN", "Düren, Nordrhein-Westfalen"),
    region("DO", "Dortmund, Nordrhein-Westfalen"),
    region("DON", "Donau-Ries (Donauwörth), Bayern"),
    region("DS", "Donaueschingen, Baden-Württemberg"),
    region("DT", "Lippe (Detmold), Nordrhein-Westfalen"),
    region("DU", "Duisburg, Nordrhein-Westfalen"),
    region("DUD", "Duderstadt, Niedersachsen"),
    region("DÜW", "Bad Dürkheim, Rheinland-Pfalz"),
    region("DW", "Dippoldiswalde, Sachsen"),
    region("DZ", "Delitzsch, Sachsen"),
    region("E", "Essen, Nordrhein-Westfalen"),
    region("EA", "Eisenach, Thüringen"),
    region("EB", "Eilenburg, Sachsen"),
    region("EBE", "Ebersberg, Bayern"),
    region("EBN", "Ebern, Bayern"),
    region("EBS", "Ebermannstadt, Bayern"),
    region("ECK", "Eckernförde, Schleswig-Holstein"),
    region("ED", "Erding, Bayern"),
    region("EE", "Elbe-Elster, Brandenburg"),
    region("EF", "Erfurt, Thüringen"),
    region("EG", "Eggenfelden, Bayern"),
    region("EH", "Eisenhüttenstadt, Brandenburg"),
    region("EHI", "Ehingen (Donau), Baden-Württemberg"),
    region("EI", "Eichstätt, Bayern"),
    region("EIC", "Eichsfeld, Thüringen"),
    region("EIH", "Eichstätt, Bayern"),
    region("EIL", "Eisleben, Sachsen-Anhalt"),
    region("EIN", "Einbeck, Niedersachsen"),
    region("EIS", "Eisenberg, Thüringen"),
    region("EL", "Emsland, Niedersachsen"),
    region("EM", "Emmendingen, Baden-Württemberg"),
    region("EMD", "Emden, Niedersachsen"),
    region("EMS", "Rhein-Lahn-Kreis (Bad Ems), Rheinland-Pfalz"),
    region("EN", "Ennepe-Ruhr-Kreis, Nordrhein-Westfalen"),
    region("ER", "Erlangen, Bayern"),
    region("ERB", "Odenwaldkreis (Erbach), Hessen"),
    region("ERH", "Erlangen-Höchstadt, Bayern"),
    region("ERK", "Erkelenz, Nordrhein-Westfalen"),
    region("ERZ", "Erzgebirgskreis, Sachsen"),
    region("ES", "Esslingen, Baden-Württemberg"),
    region("ESA", "Eisenach, Thüringen"),
    region("ESB", "Eschenbach in der Oberpfalz, Bayern"),
    region("ESW", "Werra-Meißner-Kreis (Eschwege), Hessen"),
    region("EU", "Euskirchen, Nordrhein-Westfalen"),
    region("EUT", "Eutin, Schleswig-Holstein"),
    region("EW", "Eberswalde, Brandenburg"),
    region("F", "Frankfurt am Main, Hessen"),
    region("FAL", "Fallingbostel, Niedersachsen"),
    region("FB", "Wetteraukreis (Friedberg), Hessen"),
    region("FD", "Fulda, Hessen"),
    region("FDB", "Friedberg, Bayern"),
    region("FDS", "Freudenstadt, Baden-Württemberg"),
    region("FEU", "Feuchtwangen, Bayern"),
    region("FF", "Frankfurt (Oder), Brandenburg"),
    region("FFB", "Fürstenfeldbruck, Bayern"),
    region("FG", "Mittelsachsen (Freiberg), Sachsen"),
    region("FH", "Main-Taunus-Kreis (Frankfurt/Main-Höchst), Hessen"),
    region("FI", "Finsterwalde, Brandenburg"),
    region("FKB", "Frankenberg (Eder), Hessen"),
    region("FL", "Flensburg, Schleswig-Holstein"),
    region("FLÖ", "Flöha, Sachsen"),
    region("FN", "Bodenseekreis (Friedrichshafen), Baden-Württemberg"),
    region("FO", "Forchheim, Bayern"),
    region("FOR", "Forst, Brandenburg"),
    region("FR", "Freiburg im Breisgau, Baden-Württemberg"),
    region("FRG", "Freyung-Grafenau, Bayern"),
    region("FRI", "Friesland, Niedersachsen"),
    region("FRW", "Bad Freienwalde, Brandenburg"),
    region("FS", "Freising, Bayern"),
    region("FT", "Frankenthal, Rheinland-Pfalz"),
    region("FTL", "Freital, Sachsen"),
    region("FÜ", "Fürth, Bayern"),
    region("FÜS", "Füssen, Bayern"),
    region("FW", "Fürstenwalde, Brandenburg"),
    region("FZ", "Fritzlar-Homberg, Hessen"),
    region("G", "Gera, Thüringen"),
    region("GA", "Gardelegen, Sachsen-Anhalt"),
    region("GAN", "Bad Gandersheim, Niedersachsen"),
    region("GAP", "Garmisch-Partenkirchen, Bayern"),
    region("GC", "Glauchau, Sachsen"),
    region("GD", "Schwäbisch Gmünd, Baden-Württemberg"),
    region("GDB", "Gadebusch, Mecklenburg-Vorpommern"),
    region("GE", "Gelsenkirchen, Nordrhein-Westfalen"),
    region("GEL", "Geldern, Nordrhein-Westfalen"),
    region("GEM", "Gmünden am Main, Bayern"),
    region("GEO", "Gerolzhofen, Bayern"),
    region("GER", "Germersheim, Rheinland-Pfalz"),
    region("GF", "Gifhorn, Niedersachsen"),
    region("GG", "Groß-Gerau, Hessen"),
    region("GHA", "Geithain, Sachsen"),
    region("GHC", "Gräfenhainichen, Sachsen-Anhalt"),
    region("GI", "Gießen, Hessen"),
    region("GK", "Geilenkirchen-Heinsberg, Nordrhein-Westfalen"),
    region("GL", "Rheinisch-Bergischer Kreis (Bergisch Gladbach), Nordrhein-Westfalen"),
    region("GLA", "Gladbeck, Nordrhein-Westfalen"),
    region("GM", "Oberbergischer Kreis (Gummersbach), Nordrhein-Westfalen"),
    region("GMN", "Grimmen, Mecklenburg-Vorpommern"),
    region("GN", "Gelnhausen, Hessen"),
    region("GNT", "Genthin, Sachsen-Anhalt"),
    region("GÖ", "Göttingen, Niedersachsen"),
    region("GOA", "Sankt Goar, Rheinland-Pfalz"),
    region("GOH", "St. Goarshausen, Rheinland-Pfalz"),
    region("GP", "Göppingen, Baden-Württemberg"),
    region("GR", "Görlitz, Sachsen"),
    region("GRA", "Grafenau, Bayern"),
    region("GRH", "Großenhain, Sachsen"),
    region("GRI", "Bad Griesbach i. Rottal, Bayern"),
    region("GRM", "Grimma, Sachsen"),
    region("GRS", "Gransee, Brandenburg"),
    region("GRZ", "Greiz, Thüringen"),
    region("GS", "Goslar, Niedersachsen"),
    region("GT", "Gütersloh, Nordrhein-Westfalen"),
    region("GTH", "Gotha, Thüringen"),
    region("GÜ", "Güstrow, Mecklenburg-Vorpommern"),
    region("GUB", "Guben, Brandenburg"),
    region("GUN", "Gunzenhausen, Bayern"),
    region("GV", "Grevenbroich, Nordrhein-Westfalen"),
    region("GVM", "Grevesmühlen, Mecklenburg-Vorpommern"),
    region("GW", "Vorpommern-Greifswald, Mecklenburg-Vorpommern"),
    region("GZ", "Günzburg, Bayern"),
    region("H", "Hannover, Niedersachsen"),
    region("HA", "Hagen, Nordrhein-Westfalen"),
    region("HAB", "Hammelburg, Bayern"),
    region("HAL", "Halle, Sachsen-Anhalt"),
    region("HAM", "Hamm, Nordrhein-Westfalen"),
    region("HAS", "Haßberge (Haßfurt), Bayern"),
    region("HB", "Hansestadt Bremen, Freie Hansestadt Bremen"),
    region("HBN", "Hildburghausen, Thüringen"),
    region("HBS", "Halberstadt, Sachsen-Anhalt"),
    region("HC", "Hainichen, Sachsen"),
    region("HCH", "Hechingen, Baden-Württemberg"),
    region("HD", "Heidelberg, Baden-Württemberg"),
    region("HDH", "Heidenheim (Brenz), Baden-Württemberg"),
    region("HDL", "Haldensleben, Sachsen-Anhalt"),
    region("HE", "Helmstedt, Niedersachsen"),
    region("HEB", "Hersbruck, Bayern"),
    region("HEF", "Hersfeld-Rotenburg, Hessen"),
    region("HEI", "Dithmarschen (Heide), Schleswig-Holstein"),
    region("HER", "Herne, Nordrhein-Westfalen"),
    region("HET", "Hettstedt, Sachsen-Anhalt"),
    region("HF", "Herford, Nordrhein-Westfalen"),
    region("HG", "Hochtaunuskreis (Bad Homburg), Hessen"),
    region("HGN", "Hagenow, Mecklenburg-Vorpommern"),
    region("HGW", "Hansestadt Greifswald, Mecklenburg-Vorpommern"),
    region("HH", "Hansestadt Hamburg, Freie und Hansestadt Hamburg"),
    region("HHM", "Hohenmölsen, Sachsen-Anhalt"),
    region("HI", "Hildesheim, Niedersachsen"),
    region("HIG", "Heiligenstadt, Thüringen"),
    region("HIP", "Hilpoltstein, Bayern"),
    region("HK", "Heidekreis, Niedersachsen"),
    region("HL", "Hansestadt Lübeck, Schleswig-Holstein"),
    region("HM", "Hameln-Pyrmont, Niedersachsen"),
    region("HMÜ", "Hann. Münden, Niedersachsen"),
    region("HN", "Heilbronn, Baden-Württemberg"),
    region("HO", "Hof, Bayern"),
    region("HOG", "Hofgeismar, Hessen"),
    region("HOH", "Hofheim in Unterfranken, Bayern"),
    region("HOL", "Holzminden, Niedersachsen"),
    region("HOM", "Saarpfalz-Kreis (Homburg), Saarland"),
    region("HOR", "Freudenstadt (Horb am Neckar), Baden-Württemberg"),
    region("HÖS", "Erlangen-Höchstadt, Bayern"),
    region("HOT", "Hohenstein-Ernstthal, Sachsen"),
    region("HP", "Bergstraße (Heppenheim), Hessen"),
    region("HR", "Schwalm-Eder-Kreis (Homberg), Hessen"),
    region("HRO", "Hansestadt Rostock, Mecklenburg-Vorpommern"),
    region("HS", "Heinsberg, Nordrhein-Westfalen"),
    region("HSK", "Hochsauerlandkreis, Nordrhein-Westfalen"),
    region("HST", "Hansestadt Stralsund, Mecklenburg-Vorpommern"),
    region("HU", "Hanau, Hessen"),
    region("HÜN", "Hünfeld, Hessen"),
    region("HUS", "Husum, Schleswig-Holstein"),
    region("HV", "Havelberg, Sachsen-Anhalt"),
    region("HVL", "Havelland (Rathenow), Brandenburg"),
    region("HW", "Halle (Westfalen), Nordrhein-Westfalen"),
    region("HWI", "Hansestadt Wismar, Mecklenburg-Vorpommern"),
    region("HX", "Höxter, Nordrhein-Westfalen"),
    region("HY", "Hoyerswerda, Sachsen"),
    region("HZ", "Landkreis Harz, Sachsen-Anhalt (früher: Herzberg, Brandenburg)"),
    region("IGB", "St. Ingbert, Saarland"),
    region("IK", "Ilm-Kreis, Thüringen"),
    region("IL", "Ilmenau, Thüringen"),
    region("ILL", "Illertissen, Bayern"),
    region("IN", "Ingolstadt, Bayern"),
    region("IS", "Iserlohn, Nordrhein-Westfalen"),
    region("IZ", "Steinburg (Itzehoe), Schleswig-Holstein"),
    region("J", "Jena, Thüringen"),
    region("JB", "Jüterbog, Brandenburg"),
    region("JE", "Jessen, Sachsen-Anhalt"),
    region("JEV", "Friesland (Jever), Niedersachsen"),
    region("JL", "Jerichower Land, Sachsen-Anhalt"),
    region("JÜL", "Jülich, Nordrhein-Westfalen"),
    region("K", "Köln, Nordrhein-Westfalen"),
    region("KA", "Karlsruhe, Baden-Württemberg"),
    region("KAR", "Main-Spessart (Karlstadt), Bayern"),
    region("KB", "Waldeck-Frankenberg (Korbach), Hessen"),
    region("KC", "Kronach, Bayern"),
    region("KE", "Kempten (Allgäu), Bayern"),
    region("KEH", "Kelheim, Bayern"),
    region("KEL", "Kehl, Baden-Württemberg"),
    region("KEM", "Kemnath, Bayern"),
    region("KF", "Kaufbeuren, Bayern"),
    region("KG", "Bad Kissingen, Bayern"),
    region("KH", "Bad Kreuznach, Rheinland-Pfalz"),
    region("KI", "Kiel, Schleswig-Holstein"),
    region("KIB", "Donnersbergkreis (Kirchheimbolanden), Rheinland-Pfalz"),
    region("KK", "Kempen-Krefeld, Nordrhein-Westfalen"),
    region("KL", "Kaiserslautern, Rheinland-Pfalz"),
    region("KLE", "Kleve, Nordrhein-Westfalen"),
    region("KLZ", "Klötze, Sachsen-Anhalt"),
    region("KM", "Kamenz, Sachsen"),
    region("KN", "Konstanz, Baden-Württemberg"),
    region("KO", "Koblenz, Rheinland-Pfalz"),
    region("KÖN", "Bad Königshofen i. Grabfeld, Bayern"),
    region("KÖT", "Köthen, Sachsen-Anhalt"),
    region("KÖZ", "Kötzting, Bayern"),
    region("KR", "Krefeld, Nordrhein-Westfalen"),
    region("KRU", "Krumbach, Bayern"),
    region("KS", "Kassel, Hessen"),
    region("KT", "Kitzingen, Bayern"),
    region("KU", "Kulmbach, Bayern"),
    region("KÜN", "Hohenlohekreis (Künzelsau), Baden-Württemberg"),
    region("KUS", "Kusel, Rheinland-Pfalz"),
    region("KW", "Königs Wusterhausen, Brandenburg"),
    region("KY", "Kyritz, Brandenburg"),
    region("KYF", "Kyffhäuserkreis, Thüringen"),
    region("L", "Leipzig, Sachsen (früher: Lahn-Dill-Kreis (Wetzlar), Hessen)"),
    region("LA", "Landshut, Bayern"),
    region("LAN", "Dingolfing-Landau, Bayern"),
    region("LAT", "Vogelsbergkreis (Lauterbach), Hessen"),
    region("LAU", "Nürnberger Land (Lauf a.d. Pegnitz), Bayern"),
    region("LB", "Ludwigsburg, Baden-Württemberg"),
    region("LBS", "Lobenstein, Thüringen"),
    region("LBZ", "Lübz, Mecklenburg-Vorpommern"),
    region("LC", "Luckau, Brandenburg"),
    region("LD", "Landau in der Pfalz, Rheinland-Pfalz"),
    region("LDK", "Lahn-Dill-Kreis (Wetzlar), Hessen"),
    region("LDS", "Dahme-Spreewald, Brandenburg"),
    region("LE", "Lemgo, Nordrhein-Westfalen"),
    region("LEO", "Leonberg (Württemberg), Baden-Württemberg"),
    region("LER", "Leer (Ostfriesland), Niedersachsen"),
    region("LEV", "Leverkusen, Nordrhein-Westfalen"),
    region("LF", "Laufen, Bayern"),
    region("LG", "Lüneburg, Niedersachsen"),
    region("LH", "Lüdinghausen, Nordrhein-Westfalen"),
    region("LI", "Lindau (Bodensee), Bayern"),
    region("LIB", "Bad Liebenwerda, Brandenburg"),
    region("LIF", "Lichtenfels, Bayern"),
    region("LIN", "Lingen (Ems), Niedersachsen"),
    region("LIP", "Lippe (Detmold), Nordrhein-Westfalen"),
    region("LK", "Lübbecke, Nordrhein-Westfalen"),
    region("LL", "Landsberg am Lech, Bayern"),
    region("LM", "Limburg-Weilburg, Hessen"),
    region("LN", "Lübben, Brandenburg"),
    region("LÖ", "Lörrach, Baden-Württemberg"),
    region("LÖB", "Löbau, Sachsen"),
    region("LOH", "Lohr am Main, Bayern"),
    region("LOS", "Oder-Spree, Brandenburg"),
    region("LP", "Lippstadt, Nordrhein-Westfalen"),
    region("LR", "Lahr (Schwarzwald), Baden-Württemberg"),
    region("LRO", "Landkreis Rostock, Mecklenburg-Vorpommern"),
    region("LS", "Märkischer Kreis (Lüdenscheid), Nordrhein-Westfalen"),
    region("LSZ", "Bad Langensalza, Thüringen"),
    region("LU", "Ludwigshafen am Rhein, Rheinland-Pfalz"),
    region("LÜD", "Lüdenscheid, Nordrhein-Westfalen"),
    region("LUK", "Luckenwalde, Brandenburg"),
    region("LÜN", "Lünen, Nordrhein-Westfalen"),
    region("LUP", "Ludwigslust-Parchim, Mecklenburg-Vorpommern"),
    region("LWL", "Ludwigslust, Mecklenburg-Vorpommern"),
    region("M", "München, Bayern"),
    region("MA", "Mannheim, Baden-Württemberg"),
    region("MAB", "Marienberg, Sachsen"),
    region("MAI", "Mainburg, Bayern"),
    region("MAK", "Marktredwitz, Bayern"),
    region("MAL", "Mallersdorf, Bayern"),
    region("MAR", "Marktheidenfeld, Bayern"),
    region("MB", "Miesbach, Bayern"),
    region("MC", "Malchin, Mecklenburg-Vorpommern"),
    region("MD", "Magdeburg, Sachsen-Anhalt"),
    region("ME", "Mettmann, Nordrhein-Westfalen"),
    region("MED", "Süderdithmarschen (Meldorf/Holstein), Schleswig-Holstein"),
    region("MEG", "Melsungen, Hessen"),
    region("MEI", "Meißen, Sachsen"),
    region("MEK", "Mittlerer Erzgebirgskreis, Sachsen"),
    region("MEL", "Melle, Niedersachsen"),
    region("MEP", "Meppen, Niedersachsen"),
    region("MER", "Merseburg, Sachsen-Anhalt"),
    region("MES", "Hochsauerlandkreis (Meschede), Nordrhein-Westfalen"),
    region("MET", "Mellrichstadt, Bayern"),
    region("MG", "Mönchengladbach, Nordrhein-Westfalen"),
    region("MGH", "Bad Mergentheim, Baden-Württemberg"),
    region("MGN", "Meiningen, Thüringen"),
    region("MH", "Mühlheim an der Ruhr, Nordrhein-Westfalen"),
    region("MHL", "Mühlhausen, Thüringen"),
    region("MI", "Minden-Lübbecke, Nordrhein-Westfalen"),
    region("MIL", "Miltenberg, Bayern"),
    region("MK", "Märkischer Kreis, Nordrhein-Westfalen"),
    region("MKK", "Main-Kinzig-Kreis, Hessen"),
    region("ML", "Mansfelder Land, Sachsen-Anhalt"),
    region("MM", "Memmingen, Bayern"),
    region("MN", "Unterallgäu (Mindelheim), Bayern"),
    region("MO", "Moers, Nordrhein-Westfalen"),
    region("MOD", "Marktoberdorf, Bayern"),
    region("MOL", "Märkisch-Oderland, Brandenburg"),
    region("MON", "Monschau, Nordrhein-Westfalen"),
    region("MOS", "Neckar-Odenwald-Kreis (Mosbach), Baden-Württemberg"),
    region("MQ", "Merseburg-Querfurt, Sachsen-Anhalt"),
    region("MR", "Marburg-Biedenkopf, Hessen"),
    region("MS", "Münster, Nordrhein-Westfalen"),
    region("MSE", "Mecklenburgische Seenplatte, Mecklenburg-Vorpommern"),
    region("MSH", "Mansfeld-Südharz, Sachsen-Anhalt"),
    region("MSP", "Main-Spessart (Karlstadt), Bayern"),
    region("MST", "Mecklenburg-Strelitz, Mecklenburg-Vorpommern"),
    region("MT", "Westerwald (Montabur), Rheinland-Pfalz"),
    region("MTK", "Main-Taunus-Kreis, Hessen"),
    region("MTL", "Muldentalkreis, Sachsen"),
    region("MÜ", "Mühldorf am Inn, Bayern"),
    region("MÜB", "Münchberg, Bayern"),
    region("MUC", "München, Bayern"),
    region("MÜL", "Müllheim, Baden-Württemberg"),
    region("MÜN", "Münsingen (Württemberg), Baden-Württemberg"),
    region("MÜR", "Müritz, Mecklenburg-Vorpommern"),
    region("MW", "Mittweida, Sachsen"),
    region("MY", "Mayen, Rheinland-Pfalz"),
    region("MYK", "Mayen-Koblenz, Rheinland-Pfalz"),
    region("MZ", "Mainz, Mainz-Bingen, Rheinland-Pfalz"),
    region("MZG", "Merzig-Wadern, Saarland"),
    region("N", "Nürnberg, Bayern"),
    region("NAB", "Nabburg, Bayern"),
    region("NAI", "Naila, Bayern"),
    region("NAU", "Nauen, Brandenburg"),
    region("NB", "Neubrandenburg, Mecklenburg-Vorpommern"),
    region("ND", "Neuburg-Schrobenhausen, Bayern"),
    region("NDH", "Nordhausen, Thüringen"),
    region("NE", "Neuss, Nordrhein-Westfalen"),
    region("NEA", "Neustadt an der Aisch-Bad Windsheim, Bayern"),
    region("NEB", "Nebra, Sachsen-Anhalt"),
    region("NEC", "Neustadt b. Coburg, Bayern"),
    region("NEN", "Neunburg vorm Wald, Bayern"),
    region("NES", "Rhön-Grabfeld (Bad Neustadt a.d. Saale), Bayern"),
    region("NEU", "Hochschwarzwald (Titisee-Neustadt), Baden-Württemberg"),
    region("NEW", "Neustadt an der Waldnaab, Bayern"),
    region("NF", "Nordfriesland (Husum), Schleswig-Holstein"),
    region("NH", "Neuhaus, Thüringen"),
    region("NIB", "Süd Tondern (Niebüll), Schleswig-Holstein"),
    region("NK", "Neunkirchen (Saar), Saarland"),
    region("NM", "Neumarkt in der Oberpfalz, Bayern"),
    region("NMB", "Naumburg, Sachsen-Anhalt"),
    region("NMS", "Neumünster, Schleswig-Holstein"),
    region("NÖ", "Nördlingen, Bayern"),
    region("NOH", "Grafschaft Bentheim (Nordhorn), Niedersachsen"),
    region("NOL", "Niederschlesischer Oberlausitzkreis, Sachsen"),
    region("NOM", "Northeim, Niedersachsen"),
    region("NOR", "Norden, Niedersachsen"),
    region("NP", "Neuruppin, Brandenburg"),
    region("NR", "Neuwied, Rheinland-Pfalz"),
    region("NRÜ", "Neustadt am Rübenberge, Niedersachsen"),
    region("NT", "Nürtingen, Baden-Württemberg"),
    region("NU", "Neu-Ulm, Bayern"),
    region("NVP", "Nordvorpommern, Mecklenburg-Vorpommern"),
    region("NW", "Neustadt an der Weinstraße, Rheinland-Pfalz"),
    region("NWM", "Nordwestmecklenburg, Mecklenburg-Vorpommern"),
    region("NY", "Niesky, Sachsen"),
    region("NZ", "Neustrelitz, Mecklenburg-Vorpommern"),
    region("OA", "Oberallgäu, Bayern"),
    region("OAL", "Ostallgäu, Bayern"),
    region("OB", "Oberhausen, Nordrhein-Westfalen"),
    region("OBB", "Obernburg a. Main, Bayern"),
    region("OBG", "Osterburg, Sachsen-Anhalt"),
    region("OC", "Oschersleben, Sachsen-Anhalt"),
    region("OCH", "Ochsenfurt, Bayern"),
    region("OD", "Stormarn (Bad Oldesloe), Schleswig-Holstein"),
    region("OE", "Olpe, Nordrhein-Westfalen"),
    region("OF", "Offenbach am Main, Hessen"),
    region("OG", "Ortenaukreis (Offenburg), Baden-Württemberg"),
    region("OH", "Ostholstein, Schleswig-Holstein"),
    region("OHA", "Osterode am Harz, Niedersachsen"),
    region("ÖHR", "Öhringen, Baden-Württemberg"),
    region("OHV", "Oberhavel, Brandenburg"),
    region("OHZ", "Osterholz, Niedersachsen"),
    region("OK", "Ohrekreis, Sachsen-Anhalt"),
    region("OL", "Oldenburg, Niedersachsen"),
    region("OLD", "Oldenburg in Holstein, Schleswig-Holstein"),
    region("OP", "Rhein-Wupper-Kreis (Opladen), Nordrhein-Westfalen"),
    region("OPR", "Ostprignitz-Ruppin, Brandenburg"),
    region("OR", "Oranienburg, Brandenburg"),
    region("OS", "Osnabrück, Niedersachsen"),
    region("OSL", "Oberspreewald-Lausitz, Brandenburg"),
    region("OTT", "Otterndorf, Niedersachsen"),
    region("OTW", "Ottweiler, Saarland"),
    region("OVI", "Oberviechtach, Bayern"),
    region("OVL", "Obervogtland, Sachsen"),
    region("OVP", "Ostvorpommern, Mecklenburg-Vorpommern"),
    region("OZ", "Oschatz, Sachsen"),
    region("P", "Potsdam, Brandenburg"),
    region("PA", "Passau, Bayern"),
    region("PAF", "Pfaffenhofen a.d. Ilm, Bayern"),
    region("PAN", "Rottal-Inn (Pfarrkirchen), Bayern"),
    region("PAR", "Parsberg, Bayern"),
    region("PB", "Paderborn, Nordrhein-Westfalen"),
    region("PCH", "Parchim, Mecklenburg-Vorpommern"),
    region("PE", "Peine, Niedersachsen"),
    region("PEG", "Pegnitz, Bayern"),
    region("PER", "Perleberg, Brandenburg"),
    region("PF", "Pforzheim, Baden-Württemberg"),
    region("PI", "Pinneberg, Schleswig-Holstein"),
    region("PIR", "Sächsische Schweiz-Osterzgebirge (Pirna), Sachsen"),
    region("PK", "Pritzwalk, Brandenburg"),
    region("PL", "Plauen, Sachsen"),
    region("PLÖ", "Plön, Schleswig-Holstein"),
    region("PM", "Potsdam-Mittelmark, Brandenburg"),
    region("PN", "Pößneck, Thüringen"),
    region("PR", "Prignitz (Perleberg), Brandenburg"),
    region("PRÜ", "Prüm, Rheinland-Pfalz"),
    region("PS", "Pirmasens, Rheinland-Pfalz"),
    region("PW", "Pasewalk, Mecklenburg-Vorpommern"),
    region("PZ", "Prenzlau, Brandenburg"),
    region("QFT", "Querfurt, Sachsen-Anhalt"),
    region("QLB", "Quedlinburg, Sachsen-Anhalt"),
    region("R", "Regensburg, Bayern"),
    region("RA", "Rastatt, Baden-Württemberg"),
    region("RC", "Reichenbach, Sachsen"),
    region("RD", "Rendsburg-Eckernförde, Schleswig-Holstein"),
    region("RDG", "Ribnitz-Damgarten, Mecklenburg-Vorpommern"),
    region("RE", "Recklinghausen, Nordrhein-Westfalen"),
    region("REG", "Regen, Bayern"),
    region("REH", "Rehau, Bayern"),
    region("REI", "Bad Reichenhall, Bayern"),
    region("RG", "Riesa-Großenhain, Sachsen"),
    region("RH", "Roth, Bayern"),
    region("RI", "Rinteln, Niedersachsen"),
    region("RID", "Riedenburg, Bayern"),
    region("RIE", "Riesa, Sachsen"),
    region("RL", "Rochlitz, Sachsen"),
    region("RM", "Röbel (Müritz), Mecklenburg-Vorpommern"),
    region("RN", "Rathenow, Brandenburg"),
    region("RO", "Rosenheim, Bayern"),
    region("ROD", "Roding, Bayern"),
    region("ROF", "Rotenburg an der Fulda, Hessen"),
    region("ROK", "Rockenhausen, Rheinland-Pfalz"),
    region("ROL", "Rottenburg a.d. Laaber, Bayern"),
    region("ROS", "Rostock, Mecklenburg-Vorpommern"),
    region("ROT", "Rothenburg o.d. Tauber, Bayern"),
    region("ROW", "Rotenburg (Wümme), Niedersachsen"),
    region("RP", "Rhein-Pfalz-Kreis, Rheinland-Pfalz"),
    region("RS", "Remscheid, Nordrhein-Westfalen"),
    region("RSL", "Roßlau, Sachsen-Anhalt"),
    region("RT", "Reutlingen, Baden-Württemberg"),
    region("RU", "Rudolstadt, Thüringen"),
    region("RÜD", "Rheingau-Taunus-Kreis, Hessen"),
    region("RÜG", "Rügen, Mecklenburg-Vorpommern"),
    region("RV", "Ravensburg, Baden-Württemberg"),
    region("RW", "Rottweil, Baden-Württemberg"),
    region("RY", "Rheydt, Nordrhein-Westfalen"),
    region("RZ", "Herzogtum Lauenburg (Ratzeburg), Schleswig-Holstein"),
    region("S", "Stuttgart, Baden-Württemberg"),
    region("SAB", "Saarburg, Rheinland-Pfalz"),
    region("SAD", "Schwandorf, Bayern"),
    region("SÄK", "Säckingen, Baden-Württemberg"),
    region("SAN", "Stadtsteinach, Bayern"),
    region("SAW", "Altmarkkreis Salzwedel, Sachsen-Anhalt"),
    region("SB", "Saarbrücken, Saarland"),
    region("SBG", "Strasburg, Mecklenburg-Vorpommern"),
    region("SBK", "Schönebeck, Sachsen-Anhalt"),
    region("SC", "Schwabach, Bayern"),
    region("SCZ", "Schleiz, Thüringen"),
    region("SDH", "Sondershausen, Thüringen"),
    region("SDL", "Stendal, Sachsen-Anhalt"),
    region("SDT", "Schwedt (Oder), Brandenburg"),
    region("SE", "Segeberg, Schleswig-Holstein"),
    region("SEB", "Sebnitz, Sachsen"),
    region("SEE", "Seelow, Brandenburg"),
    region("SEF", "Scheinfeld, Bayern"),
    region("SEL", "Selb, Bayern"),
    region("SF", "Sonthofen, Bayern"),
    region("SFA", "Soltau-Fallingbostel, Niedersachsen"),
    region("SFB", "Senftenberg, Brandenburg"),
    region("SFT", "Staßfurt, Sachsen-Anhalt"),
    region("SG", "Solingen, Nordrhein-Westfalen"),
    region("SGH", "Sangerhausen, Sachsen-Anhalt"),
    region("SHA", "Schwäbisch Hall, Baden-Württemberg"),
    region("SHG", "Schaumburg, Niedersachsen"),
    region("SHK", "Saale-Holzland-Kreis, Thüringen"),
    region("SHL", "Suhl, Thüringen"),
    region("SI", "Siegen-Wittgenstein, Nordrhein-Westfalen"),
    region("SIG", "Sigmaringen, Baden-Württemberg"),
    region("SIM", "Rhein-Hunsrück-Kreis (Simmern), Rheinland-Pfalz"),
    region("SK", "Saalekreis, Sachsen-Anhalt"),
    region("SL", "Schleswig-Flensburg, Schleswig-Holstein"),
    region("SLE", "Schleiden, Nordrhein-Westfalen"),
    region("SLF", "Saalfeld-Rudolstadt, Thüringen"),
    region("SLG", "Saulgau (Württemberg), Baden-Württemberg"),
    region("SLK", "Salzlandkreis, Sachsen-Anhalt"),
    region("SLN", "Schmölln, Thüringen"),
    region("SLS", "Saarlouis, Saarland"),
    region("SLÜ", "Schlüchtern, Hessen"),
    region("SLZ", "Bad Salzungen, Thüringen"),
    region("SM", "Schmalkalden-Meiningen, Thüringen"),
    region("SMÜ", "Schwabmünchen, Bayern"),
    region("SN", "Schwerin, Mecklenburg-Vorpommern"),
    region("SNH", "Sinsheim, Baden-Württemberg"),
    region("SO", "Soest, Nordrhein-Westfalen"),
    region("SOB", "Schrobenhausen, Bayern"),
    region("SOG", "Schongau, Bayern"),
    region("SOK", "Saale-Orla-Kreis (Schleiz), Thüringen"),
    region("SOL", "Soltau, Niedersachsen"),
    region("SÖM", "Sömmerda, Thüringen"),
    region("SON", "Sonneberg, Thüringen"),
    region("SP", "Speyer, Rheinland-Pfalz"),
    region("SPB", "Spremberg, Brandenburg"),
    region("SPN", "Spree-Neiße, Brandenburg"),
    region("SPR", "Springe, Niedersachsen"),
    region("SR", "Straubing (-Bogen), Bayern"),
    region("SRB", "Strausberg, Brandenburg"),
    region("SRO", "Stadtroda, Thüringen"),
    region("ST", "Steinfurt, Nordrhein-Westfalen"),
    region("STA", "Starnberg, Bayern"),
    region("STB", "Sternberg, Mecklenburg-Vorpommern"),
    region("STD", "Stade, Niedersachsen"),
    region("STE", "Staffelstein, Bayern"),
    region("STH", "Schaumburg-Lippe (Stadthagen), Niedersachsen"),
    region("STL", "Stollberg, Sachsen"),
    region("STO", "Stockach (Sigmaringen), Baden-Württemberg"),
    region("SU", "Rhein-Sieg-Kreis (Siegburg), Nordrhein-Westfalen"),
    region("SUL", "Sulzbach-Rosenberg, Bayern"),
    region("SÜW", "Südliche Weinstraße, Rheinland-Pfalz"),
    region("SW", "Schweinfurt, Bayern"),
    region("SWA", "Rheingau-Taunus-Kreis (Bad Schwalbach), Hessen"),
    region("SY", "Grafschaft Hoya (Syke), Niedersachsen"),
    region("SZ", "Salzgitter, Niedersachsen"),
    region("SZB", "Schwarzenberg, Sachsen"),
    region("TBB", "Main-Tauber-Kreis (Tauberbischofsheim), Baden-Württemberg"),
    region("TDO", "Torgau, Delitzsch, Oschatz (Nordsachsen), Sachsen"),
    region("TE", "Tecklenburg, Nordrhein-Westfalen"),
    region("TET", "Teterow, Mecklenburg-Vorpommern"),
    region("TF", "Teltow-Fläming, Brandenburg"),
    region("TG", "Torgau, Sachsen"),
    region("THW", "Technisches Hilfswerk"),
    region("TIR", "Tirschenreuth, Bayern"),
    region("TO", "Torgau-Oschatz, Sachsen"),
    region("TÖL", "Bad Tölz-Wolfratshausen, Bayern"),
    region("TÖN", "Eiderstedt (Tönning), Schleswig-Holstein"),
    region("TP", "Templin, Brandenburg"),
    region("TR", "Trier, Rheinland-Pfalz"),
    region("TS", "Traunstein, Bayern"),
    region("TT", "Tettnang (Württemberg), Baden-Württemberg"),
    region("TÜ", "Tübingen, Baden-Württemberg"),
    region("TUT", "Tuttlingen, Baden-Württemberg"),
    region("ÜB", "Überlingen am Bodensee, Baden-Württemberg"),
    region("UE", "Uelzen, Niedersachsen"),
    region("UEM", "Ueckermünde, Mecklenburg-Vorpommern"),
    region("UER", "Uecker-Randow, Mecklenburg-Vorpommern"),
    region("UFF", "Uffenheim, Bayern"),
    region("UH", "Unstrut-Hainich-Kreis, Thüringen"),
    region("UL", "Ulm, Baden-Württemberg"),
    region("UM", "Uckermark, Brandenburg"),
    region("UN", "Unna, Nordrhein-Westfalen"),
    region("USI", "Usingen, Hessen"),
    region("V", "Vogtlandkreis, Sachsen"),
    region("VAI", "Vaihingen an der Enz, Baden-Württemberg"),
    region("VB", "Vogelsbergkreis, Hessen"),
    region("VEC", "Vechta, Niedersachsen"),
    region("VER", "Verden, Niedersachsen"),
    region("VG", "Vorpommern-Greifswald, Mecklenburg-Vorpommern"),
    region("VIB", "Vilsbiburg, Bayern"),
    region("VIE", "Viersen, Nordrhein-Westfalen"),
    region("VIT", "Viechtach, Bayern"),
    region("VK", "Völklingen, Saarland"),
    region("VL", "Villingen (Schwarzwald), Baden-Württemberg"),
    region("VOF", "Vilshofen, Bayern"),
    region("VOH", "Vohenstrauß, Bayern"),
    region("VR", "Vorpommern-Rügen, Mecklenburg-Vorpommern"),
    region("VS", "Schwarzwald-Baar-Kreis (Villingen-Schwenningen), Baden-Württemberg"),
    region("W", "Wuppertal, Nordrhein-Westfalen"),
    region("WA", "Waldeck, Hessen"),
    region("WAF", "Warendorf, Nordrhein-Westfalen"),
    region("WAK", "Wartburgkreis, Thüringen"),
    region("WAN", "Wanne-Eickel, Nordrhein-Westfalen"),
    region("WAR", "Warburg, Nordrhein-Westfalen"),
    region("WAT", "Wattenscheid, Nordrhein-Westfalen"),
    region("WB", "Wittenberg, Sachsen-Anhalt"),
    region("WBS", "Worbis, Thüringen"),
    region("WD", "Wiedenbrück, Nordrhein-Westfalen"),
    region("WDA", "Werdau, Sachsen"),
    region("WE", "Weimar, Thüringen"),
    region("WEB", "Oberwesterwaldkreis (Westerburg), Rheinland-Pfalz"),
    region("WEG", "Wegscheid, Bayern"),
    region("WEL", "Weilburg, Hessen"),
    region("WEM", "Wesermünde (Bremerhaven), Niedersachsen"),
    region("WEN", "Weiden i. d. Oberpfalz, Bayern"),
    region("WER", "Wertingen, Bayern"),
    region("WES", "Wesel, Nordrhein-Westfalen"),
    region("WF", "Wolfenbüttel, Niedersachsen"),
    region("WG", "Wangen im Allgäu, Baden-Württemberg"),
    region("WHV", "Wilhelmshaven, Niedersachsen"),
    region("WI", "Wiesbaden, Hessen"),
    region("WIL", "Wittlich, Rheinland-Pfalz"),
    region("WIS", "Wismar, Mecklenburg-Vorpommern"),
    region("WIT", "Witten, Nordrhein-Westfalen"),
    region("WIZ", "Witzenhausen, Hessen"),
    region("WK", "Wittstock, Brandenburg"),
    region("WL", "Harburg (Winsen/Luhe), Niedersachsen"),
    region("WLG", "Wolgast, Mecklenburg-Vorpommern"),
    region("WM", "Weilheim-Schongau, Bayern"),
    region("WMS", "Wolmirstedt, Sachsen-Anhalt"),
    region("WN", "Rems-Murr-Kreis (Waiblingen), Baden-Württemberg"),
    region("WND", "St. Wendel, Saarland"),
    region("WO", "Worms, Rheinland-Pfalz"),
    region("WOB", "Wolfsburg, Niedersachsen"),
    region("WOH", "Wolfhagen, Hessen"),
    region("WOL", "Wolfach, Baden-Württemberg"),
    region("WOR", "Wolfratshausen, Bayern"),
    region("WOS", "Wolfstein, Bayern"),
    region("WR", "Wernigerode, Sachsen-Anhalt"),
    region("WRN", "Waren, Mecklenburg-Vorpommern"),
    region("WS", "Wasserburg am Inn, Bayern"),
    region("WSF", "Weißenfels, Sachsen-Anhalt"),
    region("WST", "Ammerland (Westerstede), Niedersachsen"),
    region("WSW", "Weißwasser, Sachsen"),
    region("WT", "Waldshut, Baden-Württemberg"),
    region("WTL", "Wittlage, Niedersachsen"),
    region("WTM", "Wittmund, Niedersachsen"),
    region("WÜ", "Würzburg, Bayern"),
    region("WUG", "Weißenburg-Gunzenhausen, Bayern"),
    region("WÜM", "Waldmünchen, Bayern"),
    region("WUN", "Wunsiedel, Bayern"),
    region("WUR", "Wurzen, Sachsen"),
    region("WW", "Westerwaldkreis, Rheinland-Pfalz"),
    region("WZ", "Wetzlar, Hessen"),
    region("WZL", "Wanzleben, Sachsen-Anhalt"),
    region("Z", "Zwickau, Sachsen"),
    region("ZE", "Zerbst, Sachsen-Anhalt"),
    region("ZEL", "Zell (Mosel), Rheinland-Pfalz"),
    region("ZI", "Löbau-Zittau, Sachsen"),
    region("ZIG", "Ziegenhain (Schwalm-Eder-Kreis), Hessen"),
    region("ZP", "Zschopau, Sachsen"),
    region("ZR", "Zeulenroda, Thüringen"),
    region("ZS", "Zossen, Brandenburg"),
    region("ZW", "Zweibrücken, Rheinland-Pfalz"),
    region("ZZ", "Zeitz, Sachsen-Anhalt"),
];


const lookUp = deRegions.reduce((acc, region) => {
  acc[region.code] = region;
  return acc;
}, {} as Record<string, DeRegion>);

export function findRegionByCode(code: string): DeRegion | null {
    const ret = lookUp[code.toUpperCase()];
    return ret ? ret : null;
}

export default deRegions;