import { VehicleData } from "../../../context/store/reducers/importVehicles.reducer";
import numberPlateValidator from "../../../validators/numberplate.validator";
import { CountriesNumberPlates } from "../../../lib/countriesNumberPlates"
import { isEmpty } from "underscore";

type VehicleBulkFileValidator = {
    ValidateFile: (vehicles: VehicleData[]) => number;
};
  
  const vehicleBulkFileValidator: VehicleBulkFileValidator = (() => {
    let countErrors: number;
  
    const ValidateFile = (vehicles: VehicleData[]): number => {
        countErrors = 0;

        vehicles.forEach(validateVehicle);

        return countErrors;
    }
    
    const validateVehicle = (vehicle: VehicleData) => {
        validateCountry(vehicle);
        validateNumberplate(vehicle);
        validateDescription(vehicle);
    }

    const validateCountry = (vehicle: VehicleData) => {
        const country = vehicle.country.value;
        let isValidCountry = true;

        if(isEmpty(country)){
            isValidCountry = false;
        } else {
            isValidCountry = new CountriesNumberPlates().isValidCountryCode(country);
        }

        if(!isValidCountry){
            vehicle.country.errorMessageKey = "invalidCountryCode";
            vehicle.country.isValid = false;
            countErrors++;
        }
    }

    const validateNumberplate = (vehicle: VehicleData) => {
        const country = vehicle.country.value;
        let isValidNumberPlate = true;
        const numberPlate = vehicle.numberPlate.value;

        if(isEmpty(numberPlate)){
            isValidNumberPlate = false;
        } else {
            if(vehicle.country.isValid && country.toLowerCase() === "de"){
                isValidNumberPlate = numberPlateValidator.isValidGermanNumberPlate(numberPlate);
            } else {
                isValidNumberPlate = numberPlateValidator.isValidNumberPlate(numberPlate);
            }
        }

        if(!isValidNumberPlate){
            vehicle.numberPlate.errorMessageKey = "invalidNumberPlate";
            vehicle.numberPlate.isValid = false;
            countErrors++;
        }
    }

    const validateDescription = (vehicle: VehicleData) => {
        const isValidDescription = vehicle.description.value === undefined || vehicle.description.value.length <= 100;
        if (!isValidDescription){
            vehicle.description.errorMessageKey = "invalidDescriptionLength";
            vehicle.description.isValid = false;
            countErrors++;
        }
    }
  
    return {
        ValidateFile
    };
  })();

  

  
  export default vehicleBulkFileValidator;
  